import React, { useState } from "react";
import "./Subscribe.css";
import LoaderModal from "./../../components/LoaderModal";
import { toast } from "react-toastify";
import { addToNewsletter } from "../../services/newsletter";

import * as Yup from "yup";
import Content from "../../components/Content/Content";

function Subscribe() {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <section className="subscribe__section">
      <img
        className="subscribe__flower"
        src="/images/white_flower.png"
        alt="flower"
        id="topRightFlower"
      ></img>
      <img
        className="subscribe__flower"
        src="/images/white_flower.png"
        alt="flower"
        id="bottomLeftFlower"
      ></img>
      <div className="subscribe__container">
        <div className="subscribe__title">
          <p>الكثير في انتظارك اشترك الان و لا تفوت الفرصة</p>
        </div>
        <div className="subscribe__description">
          <p>
            يوجد الكثير من الاشياء في انتظارك , اشترك الان للحصول على العديد من
            المواد المجانية التي ستساعدك للوصول لاهدافك و التخلص من عاداتك
            السيئة
          </p>
        </div>
        <div className=" flex flex-row items-center justify-center mt-4">
          <button className="follow__me__btn">
            <a
              href="https://www.instagram.com/mervatstreams/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Content content="تابعني على الانستغرام" />
            </a>
          </button>
        </div>

        <div className="subscribe__form">
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              if (validationSchema.isValidSync({ email })) {
                setLoading(true);
                const res = await addToNewsletter({
                  email: email,
                  list_name: "Newsletters",
                });
                setLoading(false);
                console.log(res, "🎉");
                if (res && res.status === 201) {
                  toast("تم تسجيلك في النشرة البريدية بنجاح ✔");
                } else {
                  toast("هذا البريد الإلكتروني مسجل من قبل");
                }
              } else {
                toast("يجب عليك إدخال بريد إلكتروني صحيح");
              }
              setEmail("");
            }}
          >
            <input
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              type="email"
              placeholder="...بريدك الالكتروني"
            ></input>
            <input
              className="cursor-pointer"
              type="submit"
              value="اشترك الان"
            ></input>
          </form>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </section>
  );
}

export default Subscribe;
