import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import CourseTier from "./content/CourseTier";

const PayedCoursePage = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Header />
        <CourseTier title="قائمة دوراتي"></CourseTier>
        <Footer />
      </div>
    </div>
  );
};

export default PayedCoursePage;
