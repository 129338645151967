import React from "react";
import "./SubscribeSection.css";
import SubscribeForm from "../../components/SubscribeForm/SubscribeForm";

function SubscribeSection() {
  return (
    <section className="subscribe_section">
      <img src="/images/about_subscribe.png" alt="subscribe box"></img>
      <div className="about__subscribe__box">
        <img src="/images/subscribe_phone.png" alt="subscribe phone"></img>
        <div className="border__box">
          <SubscribeForm />
        </div>
      </div>
    </section>
  );
}

export default SubscribeSection;
