import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import Title from "./../../components/Title/Title";

const VideoPlayer = ({ url }) => {
  const [playing, setPlaying] = useState(true);

  const playerRef = useRef(null);
  return (
    <div className="w-full flex flex-col space-y-4">
      <div className="relative" style={{ paddingTop: "56.25%" }}>
        <ReactPlayer
          width="100%"
          height="100%"
          className="absolute top-0 left-0"
          ref={playerRef}
          // muted={true}
          playing={playing}
          light="https://mervatsreams.smartiniaclient.com/media/images/knowme_pic.png"
          controls={true}
          url={url}
          config={{
            file: {
              attributes: {
                controlslist: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
