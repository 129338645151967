import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import LoaderModal from "../../components/LoaderModal";
import { fetcher } from "../../services/fetcher";

const Consultations = () => {
  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/appointments/types`,
    fetcher
  );

  return (
    <div className="min-h-80 width-90-100 md:width-70-100 mx-auto my-20 text-reghda-black">
      <h1 className="text-center text-5xl">إستشارات</h1>
      <div className="flex justify-center mt-16 gap-2 flex-wrap">
        {data &&
          data.map((item) => (
            <Link
              key={item.id}
              to={`/schedule/${item.id}`}
              className="flex justify-evenly items-center flex-70 text-right  xl:text-xl  border-1 min-w-200  bg-white rounded-xl shadow-lg min-h-200 md:max-width-50-100 md:min-w-600 	"
              style={{ marginBottom: " 2%" }}>
              <div className="flex justify-center items-center text-base md:text-3xl p-4 flex-45 h-full bg-mervet-pink overflow-hidden rounded-xl">
                <h1 className="text-white text-center">{item.title}</h1>
              </div>
              <div className="flex flex-col p-4 flex-45">
                <p className="flex justify-evenly my-4">
                  <span className="flex flex-row-reverse">
                    <span className="ml-2">{item.price}</span>
                    <span>دولار</span>
                  </span>
                  <span>ثمن</span>
                </p>
                <p className="flex justify-evenly ">
                  <span className="flex flex-row-reverse mb-4">
                    <span className="ml-2">{item.duration}</span>
                    <span>دقيقة</span>
                  </span>
                  <span>المدة </span>
                </p>
              </div>
            </Link>
          ))}
      </div>
      <LoaderModal modalIsOpen={!data}></LoaderModal>
    </div>
  );
};

export default Consultations;
