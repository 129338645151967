import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import "./Blog.css";
import Title from "../../components/Title/Title";
import Content from "../../components/Content/Content";
import SeeMoreButton from "../../components/SeeMoreButton/SeeMoreButton";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";

function Blog() {
  const getPosts =
    "https://graph.instagram.com/me/media?fields=id,username,timestamp,caption,media_url,media_type,permalink&access_token=IGQVJWeDFxZA2RGdTB3c2tuZAXNxNk02SzU5aTVRVVFVTllIal90ekZAnUkJSQzNwLTJ5bzBLbjFTbXlRZAmktR0E1VDluenpNWkhacEZAHWnptcHpfUWduRGdfVWxKcGNOdGJmSlk3dTFR";

  const { data, error } = useSWR(getPosts, fetcher);

  useEffect(() => {
    if (data) {
      const onlyImages = data.data.filter(
        (item) => item.media_type === "IMAGE"
      );
      const imagesList = onlyImages.map((item) => item.media_url);
      console.log(imagesList, "🎉");
      setinstaPics(imagesList);
    }
  }, [data]);

  const [instaPics, setinstaPics] = useState([]);

  return (
    <div className="blog">
      <PageHeader title="مدونتي" />

      <section className="blog__container">
        <div className="instagram8_account">
          <p>instagram : @mervatstreams</p>
        </div>

        <div className="instagram__pics">
          {instaPics.map((instaPic, index) => {
            return (
              <img
                src={instaPic}
                alt={`instapic number ${index}`}
                key={index}
              ></img>
            );
          })}
        </div>

        <div className="seemore__btn">
          <a
            href="https://www.instagram.com/mervatstreams/"
            target="_blank"
            rel="noreferrer"
          >
            <button>رؤية المزيد</button>
          </a>
        </div>
      </section>

      <section className="youtube__section">
        <img className="leaf" src="/images/leaf.png" alt="leaf"></img>
        <div className="youtube__container">
          <div className="youtube__leftside">
            <Title
              color="pink"
              content="لا تنسوا متابعتي علر قناتي باليوتيوب"
            />
            <Content
              color="white"
              content="يوجد الكثير من الاشياء في انتظارك , اشترك الان للحصول على العديد من المواد المجانية التي ستساعدك للوصول لاهدافك و التخلص من عاداتك السيئة"
            />
            <a
              href="https://www.youtube.com/channel/UC6_O8IDsc5kNRe37bMwJv2A"
              target="_blank"
              rel="noreferrer"
            >
              <SeeMoreButton content=" اشترك الان" />
            </a>
          </div>
          <div className="youtube__rightside">
            <img src="/images/youtube_pic.png" alt="youtube"></img>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blog;
