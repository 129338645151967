import React from "react";
import CoachingItem from "../../components/CoachingItem/CoachingItem";
import "./Services.css";
import { Link } from "react-router-dom";

function Services() {
  return (
    <section className="services__section">
      <div className="services__container">
        <div className="services__title">
          <p>بماذا استطيع مساعدتك ؟</p>
        </div>

        <div className="services__description">
          <p>
            هل تريد الارتقاء بنفسك الى مستوى اعلى , انا هنا لمساعدتك للحصول على
            حياة صحية خالية من السلبية في الجانب اليومي العملي
          </p>
        </div>

        <div className="services__images">
          <Link to="/1-1_coaching">
            <CoachingItem
              route="1-1_coaching"
              coachingType="1-1 Coaching"
              coachingImg={"/images/services_1.png"}
            />
          </Link>
          <Link to="/group-coaching-courses">
            <CoachingItem
              route="group-coaching"
              coachingType="group coaching"
              coachingImg={"/images/services_2.png"}
            />
          </Link>
          <Link to="/tutorials/all-courses">
            <CoachingItem
              route="tutorials"
              coachingType="Online Courses"
              coachingImg={"/images/services_3.png"}
            />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Services;
