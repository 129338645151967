import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import useSWR from "swr";
import { reservateSession } from "../../services/scheduling";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "react-calendar/dist/Calendar.css";
import "./schedule.css";
import LoaderModal from "../../components/LoaderModal";
import { fetcher } from "../../services/fetcher";

const Schedule = () => {
  const history = useHistory();
  const { id } = useParams();

  const theme = createMuiTheme({
    direction: "rtl",
  });

  const [value, setValue] = useState(new Date());
  const [timeShow, setTimeShow] = useState(false);
  const [select, setSelect] = useState(false);
  const [time, setTime] = useState("");

  /*  Available dates */
  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/appointments/dates/available?type=${id}&offset=${new Date().getTimezoneOffset()}`,
    fetcher
  );

  const { data: dataTypeConsultation } = useSWR(
    `https://mervatsreams.smartiniaclient.com/appointments/types/${id}`,
    fetcher
  );

  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(value);
  let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(value);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(value);

  /*  Available Times for each date */

  const { data: dataTimes, error: errorTimes } = useSWR(
    `https://mervatsreams.smartiniaclient.com/appointments/sessions/?date=${ye.toString()}-${mo.toString()}-${da.toString()}&type=${id}&offset=${new Date().getTimezoneOffset()}`,
    fetcher
  );

  const onChange = (value) => {
    setValue(value);
  };

  const handleClick = async () => {
    const session = dataTimes.find((item) => item.time === time);
    history.push({
      pathname: "/checkout-consultation",
      state: { data: dataTypeConsultation, session },
    });
  };

  const onClickDay = () => {
    setTimeShow(true);
  };

  const handleSelectChange = (e) => {
    setTime(e.target.value);
    setSelect(true);
  };

  var arabicDays = [
    "اﻷحد",
    "اﻷثنين",
    "الثلاثاء",
    "اﻷربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  var ArabicMonths = [
    "جانفي",
    "فيفري",
    "مارس",
    "أفريل",
    "ماي",
    "جوان",
    "جويلية",
    "أوث",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  return (
    <div className="min-h-80  schedule text-reghda-black">
      <div className="lg:w-8/12 mx-auto  bg-white min-h-60 border-1 p-4 lg:p-8 my-16 shadow-2xl	 rounded-2xl ">
        <h1 className="text-6xl my-20 text-center font-extrabold">
          جدول المواعيد
        </h1>
        <div className="flex gap-5 flex-col-reverse lg:flex-row justify-center ">
          <div className="flex-25 self-center lg:self-start border-1 p-4 text-base mt-4 lg:mt-0 min-w-200 ">
            {dataTypeConsultation && (
              <>
                <h1 className=" pb-2  text-right 2xl:pb-4 text-2xl">
                  {dataTypeConsultation.title}
                </h1>
                <p className=" pb-2  text-right 2xl:pb-4 flex justify-between">
                  <span className="flex">
                    <span>دولار</span> <span>{dataTypeConsultation.price}</span>
                  </span>
                  <span>الثمن</span>
                </p>
                <p className="pb-2 border-bottom-1 text-right 2xl:pb-4">
                  {dataTypeConsultation.description}
                </p>
                <p className="pt-4 mb-2 text-right 2xl:pb-4">
                  <span> {arabicDays[value.getDay()]} </span>
                  <span>{value.toString().slice(8, 10)}</span>
                  <span> {ArabicMonths[value.getMonth()]} </span>
                  <span>{value.toString().slice(11, 15)}</span>
                </p>
              </>
            )}

            {timeShow && (
              <div className=" text-right text-base">
                <p> ها هي الأوقات المتاحة لهذا اليوم</p>
                {dataTimes && (
                  <div className="my-4">
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="demo-simple-select-filled-label">
                        إختر التوقيت
                      </InputLabel>
                      <ThemeProvider theme={theme}>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          value={time}
                          onChange={handleSelectChange}>
                          {dataTimes.map(
                            (time) =>
                              time.is_available && (
                                <MenuItem key={time.id} value={time.time}>
                                  {time.time}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </ThemeProvider>
                    </FormControl>
                  </div>
                )}
                {select && (
                  <button
                    onClick={handleClick}
                    className="bg-reghda-black text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg focus:outline-none hover:opacity-90"
                    type="submit">
                    حجز
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="flex-70 ">
            <Calendar
              minDate={new Date()}
              calendarType="Arabic"
              locale="ar-DZ"
              onClickDay={onClickDay}
              tileDisabled={({ date, view }) => {
                console.log("📍");
                return (
                  view === "month" &&
                  data &&
                  !data.available_dates
                    .map((date) =>
                      new Date(date).toString() !== "Invalid Date"
                        ? new Date(date)
                        : new Date(date.replaceAll("-", "/"))
                    )
                    .some((availableDates) => {
                      return (
                        date.getFullYear() === availableDates.getFullYear() &&
                        date.getMonth() === availableDates.getMonth() &&
                        date.getDate() === availableDates.getDate()
                      );
                    })
                );
              }}
              onChange={onChange}
              value={value}
            />
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={!data || !dataTypeConsultation}></LoaderModal>
    </div>
  );
};

export default Schedule;
