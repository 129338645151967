import React from "react";
import { useHistory } from "react-router-dom";
import SeeMoreButton from "../../components/SeeMoreButton/SeeMoreButton";
import "./WhoAmI.css";

function WhoAmI() {
  const history = useHistory();
  return (
    <section className="whoami__section">
      <div className="whoami__container">
        <div className="whoami__img">
          <p className="whoami">من انا ؟</p>
          <img src="/images/whoami.png" alt="who am i"></img>
        </div>
        <div className="whoami__content">
          <p className="whoami__description">
            أنا مرڤت حسين مدربة معتمدة بالصحة والحياة لأكثر من 7 سنوات متواصلة و
            لقد قمت في خلال هذه الفترة الطويلة باعداد دورات تدريبية في كيفية
            تحسين صحتك الجسدية والنفسية و بناء عادات حياتية قوية , وكيفية التغلب
            على مخاوفك
          </p>
          <SeeMoreButton
            content="قراءة المزيد"
            action={() => history.push("/about")}
          />
        </div>
      </div>
    </section>
  );
}

export default WhoAmI;
