import { PayPalButton } from "react-paypal-button-v2";
import { useHistory } from "react-router-dom";
import { getTheUserFromStorage } from "./../../../services/auth";
import { reservateSession } from "./../../../services/scheduling";
import { toast } from "react-toastify";

const PayButton = ({ total, sessionId, setLoading }) => {
  const history = useHistory();
  return (
    <PayPalButton
      style={{
        color: "blue",
        shape: "rect",
        size: "responsive",
        // label: "checkout",
      }}
      options={{
        clientId:
          "Aam7IBm4HO20KP4MUpaI-O1HBU03fMlXFZxZk32ALLHHRLfEqdMAtrOg9Zu_WkuXqU90aIKm58SuR5T7",
        currency: "USD",
        disableFunding: "card",
        //   disableFunding: "credit,card",
      }}
      amount={total}
      shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
      onSuccess={async (details, data) => {
        //Call your server to save the transaction
        if (details.status === "COMPLETED") {
          setLoading(true);
          const res = await reservateSession(
            sessionId,
            getTheUserFromStorage().user_id
          );
          if (res.status === 200) {
            setLoading(false);
            toast("العملية تمت بنجاح 🎉");
            history.push("/my-consultations");
          } else {
            setLoading(false);
            toast("العملية لم تتم بنجاح ❌");
          }
        }
      }}
      onError={(err) => {
        toast("العملية لم تتم بنجاح ❌");
        console.log("👀", err);
      }}
      catchError={(err) => {
        toast("العملية لم تتم بنجاح ❌");
        console.log("❌", err);
      }}
    />
  );
};

export default PayButton;

// c id Ae06eB3XMR5ZLW6pShc1dH6F9cVDVAd4FaPiQTYkA89WPB1H8Z1vwjL84VpacbfF13o6ThcMOGkdveWg
// secret EB15dNwKOn0wt593HzvBf77tHIOOTsN05m4ddDgskqHBYYpsRj4543kY5DaKb2T9ieRJEi4nICn7RItc

//sandbox
// sb-lg7yq5468518@personal.example.com
// GN.j7)o.
