import React from "react";
import Collapsible from "react-collapsible";
// import { Link, useLocation } from "react-router-dom";
// import { IconContext } from "react-icons";
// import { AiOutlineArrowDown } from "react-icons/ai";
import Title from "../../components/Title/Title";

const ChapterItems = ({
  setCurrentChapter,
  currentChapter,
  setupCurrent,
  chapters,
  currentModule,
  setCurrentModule,
}) => {
  return (
    <div className="flex flex-col w-full">
      {chapters.map((chapter, i) => {
        return (
          <Collapsible
            trigger={
              <div className="w-full flex flex-row items-center justify-end space-x-4 bg-reghda-black border-2 border-solid border-pink-200 text-white rounded-lg x-sm:p-1 md:p-3 2xl:p-6 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                <Title content={chapter.title} color="white" />
                <i class="fa fa-arrow-circle-down"></i>
              </div>
            }
            className="text-right x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-y-0"
            openedClassName="text-right x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-y-0"
          >
            {chapter.modules.map((module, idx) => {
              return (
                <p className="flex flex-col x-sm:p-0 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                  <p
                    onClick={() => {
                      setCurrentChapter(chapter.id);
                      setCurrentModule(module.id);
                      setupCurrent(chapter.id, module.id);
                    }}
                    key={idx}
                    className={
                      module.id === currentModule
                        ? "text-mervet-pink p-2 cursor-pointer"
                        : "text-gray-600 p-2 cursor-pointer"
                    }
                  >
                    {module.title}
                  </p>
                </p>
              );
            })}
          </Collapsible>
        );
      })}
    </div>
  );
};

export default ChapterItems;
