import { PayPalButton } from "react-paypal-button-v2";
import { useHistory } from "react-router-dom";
import { enroll } from "../../services/enrollment";
import { clearCart } from "../../services/cart";
import { createOrder } from "../../services/orders";
import { getTheUserFromStorage } from "../../services/auth";
import { toast } from "react-toastify";

const PayButton = ({ total, ids, setLoading }) => {
  const history = useHistory();
  return (
    <PayPalButton
      style={{
        color: "blue",
        shape: "rect",
        size: "responsive",
        // label: "checkout",
      }}
      options={{
        clientId:
          "Aam7IBm4HO20KP4MUpaI-O1HBU03fMlXFZxZk32ALLHHRLfEqdMAtrOg9Zu_WkuXqU90aIKm58SuR5T7",
        currency: "USD",
        disableFunding: "card",
        //   disableFunding: "credit,card",
      }}
      amount={Math.round(total)}
      // amount={`${price}`}
      shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
      onSuccess={async (details, data) => {
        setLoading(true);
        //Call your server to save the transaction
        if (details.status === "COMPLETED") {
          // create order
          await createOrder({
            user: getTheUserFromStorage().user_id,
            payment_method: "Paypal",
          });
          // enroll
          let res = await enroll({
            course: ids,
            user: getTheUserFromStorage().user_id,
          });
          if (res.status === 201) {
            setLoading(false);
            toast("العملية تمت بنجاح 🎉");
            await clearCart(getTheUserFromStorage().user_id);
            history.push("/my-courses");
          } else {
            setLoading(false);
            toast("العملية لم تتم بنجاح ❌");
          }
        }
      }}
      onError={(err) => {
        toast("العملية لم تتم بنجاح ❌");
        console.log("👀", err);
      }}
      catchError={(err) => {
        toast("العملية لم تتم بنجاح ❌");
        console.log("❌", err);
      }}
      onClick={(data, actions) => {
        // console.log("👀", data);
        // console.log("👀", actions);
      }}
    />
  );
};

export default PayButton;

// clientID : Aam7IBm4HO20KP4MUpaI-O1HBU03fMlXFZxZk32ALLHHRLfEqdMAtrOg9Zu_WkuXqU90aIKm58SuR5T7
// secret: EGXCGxvrTvYoVYqWIWkfpMe0FHFRJq81hgzFlHYzC5m_JzM-jmLf0BuvNbYemYKUU_Y2nFQIbaOFBMma
