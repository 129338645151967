import React from "react";
import Header from "../../components/Header/Header";
import CheckOut from "./content/CheckOut";

const Page = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Header></Header>
        <CheckOut></CheckOut>
      </div>
    </div>
  );
};

export default Page;
