import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import PaymentForm from "./PaymentForm";

const PUBLIC_KEY =
  "pk_live_51Il0cBJhzZrG3DiVa1vxf2Qw1j4q6V2QYwnAu8aK1yb0oREtaKsbeZLrtU3hf1TAI5cjbXP9A3XQwHFOi5k4ZNrM00iGFKRj99";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeButton = ({ sessionId }) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm sessionId={sessionId} />
    </Elements>
  );
};

export default StripeButton;
