import React from "react";
import useSWR from "swr";
import { fetcher } from "./../../services/fetcher";
import { Link } from "react-router-dom";
import LoaderModal from "../../components/LoaderModal";

const UserActivation = ({ location }) => {
  const { search } = location;
  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/users/verify-email?token=${
      search.split("=")[1]
    }`,
    fetcher
  );

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-indigo-200 h-screen w-1/2 overview-sl"></div>
      <div className="flex justify-center items-start md:w-1/2 w-full md:items-center">
        {(data || error) && (
          <div
            className="flex flex-col justify-center items-center py-4 w-80 rounded-3xl"
            style={{
              width: "40vw",
              minWidth: 280,
            }}>
            <img
              src="/images/mervet_logo.png"
              style={{ maxWidth: 150 }}
              className="py-8"
            />
            {data && <p className="text-2xl">تم تفعيل حسابك بنجاح</p>}
            {error && <p className="text-2xl">رابط غير صالح</p>}

            <Link
              to="/"
              className="bg-mervet-pink text-white text-center w-3/4 x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg my-8">
              اذهب إلى الصفحة الرئيسية
            </Link>
          </div>
        )}
        <LoaderModal modalIsOpen={!data && !error}></LoaderModal>
      </div>
    </div>
  );
};

export default UserActivation;
