import React, { useState, useEffect } from "react";
import Title from "../../components/Title/Title";
import "./FollowCourseContent.css";
import Content from "../../components/Content/Content";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ChapterItems from "./ChapterItems";
import VideoPlayer from "./VideoPlayer";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import OpenNextModel from "./OpenNextModel";
import OpenNextForGroup from "./OpenNextForGroup";

function FollowCourseContent() {
  const { slug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenGroup, setIsOpenGroup] = useState(true);

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/courses/published/follow-up/${slug}`,
    fetcher
  );
  data && console.log(data);
  const [totalState, SetTotalState] = useState({
    chapters: [],
    // numberOfChapters: 3,
  });

  const [currentUrl, setCurrentUrl] = useState("");
  const [currentAttachment, setCurrentAttachment] = useState({
    files: [],
    images: [],
    audios: [],
  });
  const [currentType, setCurrentType] = useState("video");

  const [currentChapter, setCurrentChapter] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);

  const [canUnlock, setCanUnlock] = useState(false);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    data &&
      SetTotalState({
        chapters: data.chapters.map((chapter, i) => {
          return { ...chapter, numberOfModules: chapter.modules.length };
        }),
        numberOfChapters: data.chapters.length,
      });
  }, [data]);

  const setupCurrent = (chapterId, moduleId) => {
    if (chapterId && moduleId) {
      let thisChapter = totalState.chapters.filter(
        (chapter) => chapter.id === chapterId
      )[0];
      let thisModule = thisChapter.modules.filter(
        (module) => module.id === moduleId
      )[0];

      if (thisModule.videos.length > 0) {
        thisModule.videos[0].url === ""
          ? setCurrentUrl(thisModule.videos[0].video_content)
          : setCurrentUrl(thisModule.videos[0].url);
      }

      setCurrentAttachment({
        files: [...thisModule.files],
        images: [...thisModule.images],
        audios: [...thisModule.audios],
      });
    }
  };

  return (
    <div className="course__content__1">
      <div className="course__content__1__container">
        {!currentModule && (
          <div className="course__content__leftside">
            <div className="w-full flex flex-col space-y-4 text-center items-center justify-center font-extrabold md:text-lg lg:text-2xl 2xl:text-4xl">
              <Title content="إبدا الدورة الأن بإختيار درس" />
              <img src="/images/play.svg" alt="play" className="play"></img>
            </div>
          </div>
        )}
        {currentModule && (
          <div className="course__content__leftside">
            <div className="course__item w-full">
              <div className="course__section__title">
                <img src="/images/video-solid.svg" alt="video"></img>
                <Title content="الفيديو" />
              </div>
              <div className="course__video w-full">
                <VideoPlayer
                  // next={next}
                  type={currentType}
                  url={currentUrl}></VideoPlayer>
              </div>
            </div>

            <div className="course__item">
              {currentAttachment &&
                (currentAttachment.files.length > 0 ||
                  currentAttachment.images.length > 0 ||
                  currentAttachment.audios.length > 0) && (
                  <div className="course__section__title">
                    <img src="/images/file-pdf-solid.svg" alt="pdf"></img>
                    <Title content="مرفقات" />
                  </div>
                )}
              <div className="course__attachments">
                {currentAttachment.files.length > 0 &&
                  currentAttachment.files.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.file_content)
                      : (where = item.url);
                    return (
                      <div className="course__attachment__item">
                        <a
                          href={where}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="course__attachment__download">
                          <i class="fa fa-download"></i>
                          <Content content="تحميل" />
                        </a>
                        <Content content={item.title} />
                      </div>
                    );
                  })}
                {currentAttachment.images.length > 0 &&
                  currentAttachment.images.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.image_content)
                      : (where = item.url);
                    return (
                      <div className="course__attachment__item">
                        <a
                          href={where}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="course__attachment__download">
                          <i class="fa fa-download"></i>
                          <Content content="تحميل" />
                        </a>
                        <Content content={item.title} />
                      </div>
                    );
                  })}
                {currentAttachment.audios.length > 0 &&
                  currentAttachment.audios.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.audio_content)
                      : (where = item.url);
                    return (
                      <div className="course__attachment__item">
                        <a
                          href={where}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="course__attachment__download">
                          <i class="fa fa-download"></i>
                          <Content content="تحميل" />
                        </a>
                        <Content content={item.title} />
                      </div>
                    );
                  })}
              </div>
            </div>

            {canUnlock && (
              <div className="">
                <button
                  className="text-white bg-reghda-blue x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-right"
                  onClick={() => setIsOpen(true)}>
                  إفتح التالي
                </button>
              </div>
            )}
          </div>
        )}
        <div className="course__content__rightside">
          <div className="course__program__container">
            {data && (
              <ChapterItems
                setWaiting={setWaiting}
                setCanUnlock={setCanUnlock}
                courseId={data.id}
                category={data.category}
                setCurrentChapter={setCurrentChapter}
                currentChapter={currentChapter}
                setCurrentModule={setCurrentModule}
                currentModule={currentModule}
                setupCurrent={setupCurrent}
                chapters={totalState.chapters}></ChapterItems>
            )}
          </div>
        </div>
      </div>
      {data && (
        <OpenNextModel
          courseId={data.id}
          modalIsOpen={modalIsOpen}
          closing={() => setIsOpen(false)}></OpenNextModel>
      )}
      {data && data.category === "Group Coaching" && waiting && (
        <OpenNextForGroup
          courseId={data.id}
          modalIsOpen={modalIsOpenGroup}
          closing={() => setIsOpenGroup(false)}></OpenNextForGroup>
      )}
    </div>
  );
}

export default FollowCourseContent;
