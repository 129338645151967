import React from "react";
import { useHistory } from "react-router-dom";
import "./CoachingItem.css";

function CoachingItem({ coachingImg, coachingType, route }) {
  const history = useHistory();
  return (
    <div className="service" onClick={() => history.push(route)}>
      <img src={coachingImg} alt={coachingType}></img>
      <p className="service__type">{coachingType}</p>
    </div>
  );
}

export default CoachingItem;
