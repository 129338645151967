import Axios from "axios";

export async function addToCart(data) {
  try {
    const response = await Axios.post(
      `https://mervatsreams.smartiniaclient.com/cart/add-to-cart`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteFromCart(data) {
  try {
    const response = await Axios.post(
      `https://mervatsreams.smartiniaclient.com/cart/remove-from-cart`,

      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function clearCart(id) {
  try {
    const response = await Axios.delete(
      `https://mervatsreams.smartiniaclient.com/cart/cart-details/${id}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
