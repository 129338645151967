import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateProfile } from "./../../services/profile";
import { getTheUserFromStorage } from "./../../services/auth";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import LoaderModal from "./../../components/LoaderModal";
import Title from "./../../components/Title/Title";

const ProfileForm = ({ image, setImage, setName, setDate }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    givenName: Yup.string().required("هذا الحقل مطلوب"),
    familyName: Yup.string().required("هذا الحقل مطلوب"),
  });

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/users/profile/${
      getTheUserFromStorage().user_id
    }`,
    fetcher
  );

  useEffect(() => {
    data && setImage(data.User.image);
    data && setName(`${data.User.given_name} ${data.User.family_name}`);
    data && setDate(data.User.created_at);
  }, [data]);

  return (
    <>
      {data && (
        <Formik
          initialValues={{
            givenName: data.User.given_name,
            familyName: data.User.family_name,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            const formData = new FormData();
            formData.append(`given_name`, values.givenName);
            formData.append(`family_name`, values.familyName);
            if (image && typeof image !== "string") {
              formData.append(`image`, image);
            }
            let res = await updateProfile(
              getTheUserFromStorage().user_id,
              formData
            );
            if (res.status === 200) {
              setLoading(false);
              mutate(
                `https://mervatsreams.smartiniaclient.com/users/profile/${
                  getTheUserFromStorage().user_id
                }`
              );
            } else {
              setLoading(false);
              alert("something wrong,retry");
            }
            setSubmitting(false);
          }}>
          <Form className="flex flex-col x-sm:space-y-6 md:space-y-6 2xl:space-y-12 text-right x-sm:text-xs  sm:text-sm md:text-xl 2xl:text-4xl">
            <fieldset className="flex flex-row items-center justify-between space-x-0">
              <Field
                name="givenName"
                type="text"
                defaultValue={data.User.given_name}
                class="bg-reghda-blue x-sm:w-full md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
              />
              <label htmlFor="name" className="pl-4">
                <Title color="#5C5C5C" content="الأسم" />
              </label>
            </fieldset>
            <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="givenName" />
            </div>
            <fieldset className="flex flex-row items-center justify-between space-x-0">
              <Field
                name="familyName"
                defaultValue={data.User.family_name}
                type="text"
                class="bg-reghda-blue x-sm:w-full md:w-2/4 md:ml-44 text-white text-right p-2 pr-6 rounded-2xl shadow-lg outline-none focus:outline-none  focus:border-pink-300"
              />
              <label htmlFor="name" className="pl-4">
                <Title color="#5C5C5C" content="اللقب" />
              </label>
            </fieldset>
            <div className="text-center text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="familyName" />
            </div>
            <div className="flex flex-row items-start justify-center">
              <button
                type="submit"
                className="bg-reghda-black float-left text-white py-2 px-4 rounded-xl x-sm:text-sm md:text-base 2xl:text-3xl">
                حفظ
              </button>
            </div>
            <LoaderModal modalIsOpen={loading}></LoaderModal>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ProfileForm;
