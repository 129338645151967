import React from "react";
import "./Footer.css";
import { useHistory, useLocation, Link } from "react-router-dom";

function Footer() {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleRoute = (route) => {
    history.push(route);
  };

  if (pathname.includes("login") || pathname.includes("signup")) {
    return null;
  }

  return (
    <footer>
      <div className="footer__container">
        <div className="footer__menu">
          <div className="footer__right">
            <div>
              <p className="mb-4">القائمة</p>
              <ul>
                <span onClick={() => handleRoute("/")}>
                  <li>الرئيسية</li>
                </span>
                <span onClick={() => handleRoute("/blog")}>
                  <li>مدونتي</li>
                </span>
                <span onClick={() => handleRoute("/about")}>
                  <li>نبذة عني</li>
                </span>
                <span onClick={() => handleRoute("/1-1_coaching")}>
                  <li>اعمل معي</li>
                </span>
                <span onClick={() => handleRoute("/tutorials/free-courses")}>
                  <li>دوراتي المجانية</li>
                </span>
                <span onClick={() => handleRoute("/contact")}>
                  <li>تواصل معي</li>
                </span>
              </ul>
            </div>
          </div>
          <div className="footer__middle">
            <Link to="/">
              <img src="/images/mervet_logo.png" alt="mervet logo"></img>
            </Link>
          </div>
          <div className="footer__left">
            <ul>
              <a
                href="https://www.youtube.com/channel/UC6_O8IDsc5kNRe37bMwJv2A"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src="/images/youtube.png" alt="inst"></img> يوتيوب
                </li>
              </a>
              <a
                href="https://www.instagram.com/mervat4444/"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src="/images/instagram.svg" alt="inst"></img> انستغرام
                </li>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=13144771253&text=Health%20and%20life%20coach%20"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src="/images/whatsapp.svg" alt="inst"></img> واتساب
                </li>
              </a>
              <a
                href="https://www.facebook.com/mervatcare/"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src="/images/facebook.svg" alt="inst"></img> فيسبوك
                </li>
              </a>
              <a
                href="mailto:mervatstream@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <img src="/images/email.svg" alt="inst"></img> ايميل
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className="footer__copyright">
          <a
            href="https://smartinia.com/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row space-x-4 items-center justify-center mt-6"
          >
            <img
              src="/images/smartinia.png"
              className="x-sm:h-6 sm:h-10"
              alt="icon"
            />
            <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
              تم الانشاء بواسطة
            </p>
          </a>
          <p> جميع الحقوق محفوظة Streams Mervat© 2021</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
