import React, { useState, useEffect, useContext } from "react";
import { storeContext } from "../../global/store";
import "./Coaching_1_1.css";
import Content from "../../components/Content/Content";
import Title from "../../components/Title/Title";
import CoachingSubscribeButton from "../../components/CoachingSubscribeButton/CoachingSubscribeButton";
import LatinTitle from "../../components/LatinTitle/LatinTitle";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { getTheUserFromStorage } from "../../services/auth";
import { addToCart } from "../../services/cart";
import LoaderModal from "./../../components/LoaderModal";
import OneCoachingCourses from "../one-coaching-courses/Page";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import Loader from "react-loader-spinner";
import { updateVisit } from "../../services/oneOneCoaching";

const OneOneCoaching = () => {
  const { store } = useContext(storeContext);
  const history = useHistory();

  const { data, error, mutate } = useSWR(
    store.isLogged &&
      getTheUserFromStorage().user_id &&
      `https://mervatsreams.smartiniaclient.com/subscriptions/follow-up/check/visits?user=${
        getTheUserFromStorage().user_id
      }&category=1-1+Coaching`,
    fetcher
  );
  console.log(data);

  if (store.isLogged && !data) {
    return (
      <div
        style={{ height: "60vh" }}
        className="flex flex-col items-center justify-center">
        <Loader type="Oval" height={70} width={70} color={"#e7bdcb"} />
      </div>
    );
  }

  if (store.isLogged && data && data.is_visited) {
    return <OneCoachingCourses></OneCoachingCourses>;
  }

  return <Coaching_1_1 mutate={mutate}></Coaching_1_1>;
};

function Coaching_1_1({ mutate }) {
  const { store } = useContext(storeContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <div className="coaching1_1">
      <section className="coaching1_1__intro">
        <div className="coaching1_1__intro__container">
          <div className="coaching1_1__intro__leftside">
            <img src="/images/1_1_coaching.png" alt="coaching"></img>
          </div>
          <div className="coaching1_1__intro__rightside">
            <LatinTitle content="1-1 Coaching" />
            <Content
              font="big"
              color="white"
              content="انا هنا لتقديم العون لك في إنشاء تغيير حقيقي ،من الهام روحك"
            />
          </div>
        </div>
      </section>

      <section className="coaching1_1__questions">
        <img
          src="/images/white_points.png"
          id="leftPoints"
          className="white__points"
          alt="white-points"></img>
        <img
          src="/images/white_points.png"
          id="rightPoints"
          className="white__points"
          alt="white-points"></img>
        <div className="coaching1_1__questions__container">
          <Title content="هل سئمت من نفس نمط الدمار؟" />
          <Title content="هل تعبت من الشعور بالتوتر والإرهاق؟" />
          <Title content="هل تريد حلاً لبدء عيش حياة مليئة بالإنجاز؟" />
        </div>
      </section>

      <section className="coaching1_1__definition">
        <div className="coaching1_1__definition__container">
          <div className="coaching1_1__definition__leftside">
            <img
              src="/images/1_1_coaching_definition.png"
              alt="definition"></img>
          </div>
          <div className="coaching1_1__definition__rightside">
            <Title content="المبادرة" font="big" />
            <Content content="1-1 هي برنامج مدته 12 اسبوعا،والذي يتضمن 60 دقيقة ، أسبوعيا جلسات ، والتي تتم عبر zoom" />
            <Content content="يوفر البرنامج" />
            <ul>
              <li>
                <i className="fa fa-check"></i> <Content content="دعم شخصي" />{" "}
              </li>
              <li>
                <i className="fa fa-check"></i>{" "}
                <Content content="أسئلة مستمرة" />{" "}
              </li>
              <li>
                <i className="fa fa-check"></i>{" "}
                <Content content="خطوات قابلة للتنفيذ تناسب حياتك المزدحمة" />{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="youwillbe__able">
        <Title content="خلال 12 أسبوعًا من تجربتك ، سنقوم بما يلي" font="big" />
        <Content content="إنشاء خطوات قابله للتحقيق متمكن منها تتناسب مع حياتك المزدحمة" />
        <Content content="اكتشف ما يعيقك ثم أطلق العنان لإمكانياتك" />
      </section>

      <section className="isupport__you">
        <div className="isupport__you__container">
          <div className="isupport__you__leftside">
            <img
              src="/images/1_1_coaching_definition.png"
              alt="definition"></img>
          </div>
          <div className="isupport__you__rightside">
            <Title content="ادعمك" font="big" />
            <ul>
              <li>
                <i className="fa fa-check"></i>{" "}
                <Content content="بصفتي مدربًا لك ، أنا هنا لتقديم دعم شخصي يحفزك و يشجعك" />{" "}
              </li>
              <li>
                <i className="fa fa-check"></i>{" "}
                <Content content="ساساندك لتمتد خارج منطقة راحتك" />{" "}
              </li>
              <li>
                <i className="fa fa-check"></i>{" "}
                <Content content="سوف أتحداك أن تصبح فضوليًا في تفعيل قانون السوال و كيف تقترب حاليًا من المجالات المختلفة في حياتك" />{" "}
              </li>
              <li>
                <i className="fa fa-check"></i>{" "}
                <Content content="أنا هنا لأدربك وأرشدك لاتخاذ خطوة نحو رسم خط مسار جديد في كل مجال من مجالات حياتك" />{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="getwhatyouwant__section">
        <div className="getwhatyouwant__row">
          <div className="row1__leftside">
            <img src="/images/getwhatyouwant.png" alt="getwhatyouwant"></img>
          </div>
          <div className="row1__rightside">
            <Title content="احصل على ما تريد" />
            <Content content=": المجالات التي أنا مجهز ومؤهّل لدعمك بها" />
            <div className="getwhatyouwant__title">
              <i className="fa fa-check"></i>{" "}
              <Content content=": الاتصال بالخالق" />
            </div>
            <ul className="getwhatyouwant__content">
              <li>
                <Content content="اكتشف غايتك في الحياه -" />
              </li>
              <li>
                <Content content="التعهد بمصادر اكبر للطاقه والأبداع والإلهام -" />
              </li>
              <li>
                <Content content="تعرف على ابواب الاتصال بالخالق والشعور بالرضى -" />
              </li>
            </ul>
          </div>
        </div>

        <div className="getwhatyouwant__row">
          <div className="row1__rightside">
            <div className="getwhatyouwant__title">
              <i className="fa fa-check"></i> <Content content=": المال" />
            </div>
            <ul className="getwhatyouwant__content">
              <li>
                <Content content="كيفيه ايجاد طاقه المنح والعطاء -" />
              </li>
              <li>
                <Content content="سد منافذ استنزاف المال -" />
              </li>
              <li>
                <Content content="تمرين المراه -" />
              </li>
              <li>
                <Content content="ما هو نبراس الوفره -" />
              </li>
            </ul>
          </div>
          <div className="row1__rightside">
            <div className="getwhatyouwant__title">
              <i className="fa fa-check"></i>{" "}
              <Content content=": مسار المهني( الوظيفه)" />
            </div>
            <ul className="getwhatyouwant__content">
              <li>
                <Content content="تمرين الغايه من الحياه -" />
              </li>
              <li>
                <Content content="اصنع المزيد مما تحب -" />
              </li>
              <li>
                <Content content="نشاط توفير الموارد -" />
              </li>
              <li>
                <Content content="ضبط علم التنبؤ -" />
              </li>
            </ul>
          </div>
          <div className="row1__rightside">
            <div className="getwhatyouwant__title">
              <i className="fa fa-check"></i> <Content content=": الحياه" />
            </div>
            <ul className="getwhatyouwant__content">
              <li>
                <Content content="عجله الحياه -" />
              </li>
              <li>
                <Content content="اسرار كينونتك -" />
              </li>
              <li>
                <Content content="سباب جلد الذات والحل -" />
              </li>
              <li>
                <Content content="قايمه الرغبات -" />
              </li>
            </ul>
          </div>
        </div>

        <div className="getwhatyouwant__row">
          <div className="row1__rightside">
            <div className="getwhatyouwant__title">
              <i className="fa fa-check"></i>{" "}
              <Content content=": الحب(العلاقات)" />
            </div>
            <ul className="getwhatyouwant__content">
              <li>
                <Content content="كيف تصنع علاقات تدعم طاقتك -" />
              </li>
              <li>
                <Content content="قائمه كيف تشعر بالحب -" />
              </li>
              <li>
                <Content content="كيف تطلب ماتريد وتحصل عليه -" />
              </li>
              <li>
                <Content content="طرق الاتصال والتواصل -" />
              </li>
            </ul>
          </div>
          <div className="row1__rightside">
            <div className="getwhatyouwant__title">
              <i className="fa fa-check"></i> <Content content=": الصحه" />
            </div>
            <ul className="getwhatyouwant__content">
              <li>
                <Content content="ادراك ميزان الجوع والشبع -" />
              </li>
              <li>
                <Content content="تعرف على الاطعمه التي تمدك بالطاقه -" />
              </li>
              <li>
                <Content content="تمرين المضغ -" />
              </li>
              <li>
                <Content content="اسرار حرق المزيد الدهون -" />
              </li>
            </ul>
          </div>
        </div>
      </section>

      {!store.isLogged && (
        <CoachingSubscribeButton
          action={() => {
            history.push("/login");
          }}
        />
      )}

      {store.isLogged && (
        <CoachingSubscribeButton
          action={async () => {
            setLoading(true);
            let res = await updateVisit(getTheUserFromStorage().user_id, {
              is_visited: true,
            });
            if (res.status === 200) {
              setLoading(false);
              mutate();
            } else {
              setLoading(false);
              alert("something wrong,retry");
            }
          }}
        />
      )}

      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
}

export default OneOneCoaching;
