import React from "react";
import "./Content.css";
function Content({ content, color, font, textalign, style }) {
  return (
    <p
      style={{
        color: color === "gray" ? "#5B5B5B" : color,
        textAlign: textalign ? textalign : "end",
        ...style,
      }}
      className={font === "big" ? "content content__big" : "content"}
    >
      {content}
    </p>
  );
}

export default Content;
