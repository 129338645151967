import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { pay } from "../../services/payWithStripe";
import { createOrder } from "../../services/orders";
import { enroll } from "../../services/enrollment";
import { getTheUserFromStorage } from "./../../services/auth";
import { clearCart } from "../../services/cart";
import { toast } from "react-toastify";

const PUBLIC_KEY =
  "pk_live_51Il0cBJhzZrG3DiVa1vxf2Qw1j4q6V2QYwnAu8aK1yb0oREtaKsbeZLrtU3hf1TAI5cjbXP9A3XQwHFOi5k4ZNrM00iGFKRj99";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const ApplePay = ({ cartId, total }) => {
  const [show, setShow] = useState(true);
  console.log("show", show);
  return (
    <>
      {show && (
        <>
          <br />
          <br />
          <div className="flex flex-row items-center justify-end py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الدفع عبر
            </p>
          </div>
          <div className="flex flex-col items-center justify-between pt-4 pb-8 max-w-[547px] w-full h-full px-12">
            {/* <div className="flex flex-row">
            <h1 className="font-bold text-title text-black w-full">
              Apple pay
            </h1>
          </div> */}
            <div className="w-full pt-4 my-auto">
              <Elements stripe={stripeTestPromise}>
                <PaymentForm total={total} cartId={cartId} setShow={setShow} />
              </Elements>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const PaymentForm = ({ cartId, total, setShow }) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  // console.log("paymentRequest", paymentRequest);
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Total",
        amount: total * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        setShow(true);
      } else {
        setShow(false);
      }
    });

    pr.on("paymentmethod", async (e) => {
      const res = await pay({
        cart: cartId,
      });
      // console.log("res", res);

      if (!res) {
        return;
      }

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(
          res?.data?.data?.client_secret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        toast(stripeError?.message);
        setLoading(false);
        return;
      }

      if (paymentIntent?.status === "succeeded") {
        // create order
        await createOrder({
          user: getTheUserFromStorage()?.user_id,
          payment_method: "Card",
        });
        //enroll
        await enroll({
          course: res?.data?.courses,
          user: getTheUserFromStorage()?.user_id,
        });
        await clearCart(getTheUserFromStorage()?.user_id);
        setLoading(false);
        toast("تم الدفع بنجاح 🎉");
        history.push("/my-courses");
      }
      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
    });
  }, [stripe, elements]);
  return (
    <div>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </div>
  );
};

export default ApplePay;
