import axios from "axios";

export async function resetPassword(userId, token, newPassword) {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.patch(
      `https://mervatsreams.smartiniaclient.com/users/reset-password/${userId}/${token}`,
      { password: newPassword },
      config
    );
    return response;
  } catch (error) {
    return { error };
  }
}

export async function sendEmailResetPassword(email) {
  try {
    const response = await axios.post(
      "https://mervatsreams.smartiniaclient.com/users/forget-password",
      { email }
    );
    return response;
  } catch (error) {
    return { error };
  }
}
