import React from "react";
import Title from "../Title/Title";
import "./CoachingSubscribeButton.css";
function CoachingSubscribeButton({ action, title }) {
  return (
    <button onClick={() => action()} className="coaching__subscribe__btn">
      <Title content={title ? title : "اشترك الان"} color="white" />
    </button>
  );
}

export default CoachingSubscribeButton;
