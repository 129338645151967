import Axios from "axios";

export async function addToNewsletter(data) {
  try {
    const response = await Axios.post(
      `https://mervatsreams.smartiniaclient.com/newsletters/list/add`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
