import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import PayButton from "./PayButton";
import StripeButton from "./StripeButton";
import ApplePay from "./ApplePay";
import LoaderModal from "../../../components/LoaderModal";

const CheckOut = () => {
  const history = useHistory();
  const title = history.location.state.data.title;
  const date = history.location.state.session.date;
  const time = history.location.state.session.time;
  const duration = history.location.state.data.duration;
  const price = history.location.state.data.price;
  const sessionId = history.location.state.session.id;

  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          <div className="flex flex-row items-center justify-between py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              {`${title}`}
            </p>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              نوع الجلسة
            </p>
          </div>
          <div className="flex flex-row items-center justify-between py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              {`${date}`}
            </p>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              تاريخ البدء
            </p>
          </div>
          <div className="flex flex-row items-center justify-between py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              {`${time}`}
            </p>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              وقت البدء
            </p>
          </div>
          <div className="flex flex-row items-center justify-between py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              {`دقيقة ${duration}`}
            </p>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              المدة الزمنية
            </p>
          </div>

          <div className="flex flex-row items-center justify-between py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              {`${price} $`}
            </p>
            <p className="text-reghda-pink text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الثمن
            </p>
          </div>
        </div>
      </div>
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          <p className="pb-4 text-reghda-blue text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
            الدفع
          </p>
          <div className="flex flex-row items-center justify-between py-4">
            <div className="flex flex-row space-x-4 items-center justify-center">
              <img
                src="/images/footer/visa.png"
                className="x-sm:h-6 sm:h-10"
                alt="icon"
              />
              <img
                src="/images/footer/mastercard.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
            </div>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الدفع عبر
            </p>
          </div>
          <StripeButton
            sessionId={sessionId}
            setLoading={setLoading}></StripeButton>
          <ApplePay
            setLoading={setLoading}
            sessionId={sessionId}
            total={price}
          />
          <br />
          <br />
          <div className="flex flex-row items-center justify-between py-4">
            <div className="flex flex-row space-x-4 items-center justify-center">
              <img
                src="/images/footer/paypal.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
            </div>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الدفع عبر
            </p>
          </div>
          <PayButton
            setLoading={setLoading}
            sessionId={sessionId}
            total={price}></PayButton>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CheckOut;
