import React, { useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { sendEmailUserActivation } from "../../services/userActivation";
import { Formik, Field, Form, ErrorMessage } from "formik";
import LoaderModal from "../../components/LoaderModal";

const UserReactivation = ({ search }) => {
  const [resendEmail, setResendEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-indigo-200 h-screen w-1/2 overview-sl"></div>
      <div className="flex justify-center items-start md:w-1/2 w-full md:items-center">
        <div
          className="flex flex-col justify-center items-center py-4 w-80 rounded-3xl"
          style={{
            width: "40vw",
            minWidth: 280,
          }}>
          <img
            src="/images/mervet_logo.png"
            style={{ maxWidth: 150 }}
            className="py-8"
          />
          {!emailSent && (
            <p className="text-center mb-6 text-xl md:text-2xl">
              تم إرسال بريد إلكتروني للتفعيل إلى عنوان بريدك الإلكتروني ، يمكنك
              إعادة الإرسال والبريد الإلكتروني بالنقر فوق الزر
            </p>
          )}
          {!resendEmail && !emailSent && (
            <button
              className="bg-mervet-pink text-white text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
              onClick={() => setResendEmail(true)}>
              إعادة إرسال
            </button>
          )}
          {resendEmail && !emailSent && (
            <div className="w-full">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  setLoading(true);
                  const { email } = values;
                  const res = await sendEmailUserActivation(email);
                  setLoading(false);
                  if (res && res.status === 200) {
                    setEmailSent(true);
                  } else {
                    alert("لا يوجد مستخدم بهذا البريد الإلكتروني");
                  }
                  setSubmitting(false);
                }}>
                <Form className="text-white x-sm:space-y-2 md:space-y-4 2xl:space-y-6 w-full flex flex-col items-center">
                  <Field
                    className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
                    placeholder="البريد الالكتروني"
                    name="email"
                    type="email"
                  />
                  <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                    <ErrorMessage name="email" />
                  </div>
                  <button
                    className="bg-mervet-pink text-white text-center w-1/2  x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
                    type="submit">
                    إرسال
                  </button>
                </Form>
              </Formik>
            </div>
          )}
          {emailSent && (
            <>
              <p className="text-center mb-6 text-xl md:text-2xl">
                تم إرسال بريد إلكتروني جديد إلى عنوان بريدك الإلكتروني
              </p>
              <button
                onClick={() => setEmailSent(false)}
                className="bg-mervet-pink text-white text-center w-1/2  x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg">
                إعادة إرسال
              </button>
            </>
          )}
          <Link
            to="/"
            className="bg-mervet-pink text-white text-center w-3/4 x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg my-8">
            اذهب إلى الصفحة الرئيسية
          </Link>
        </div>

        <LoaderModal modalIsOpen={loading}></LoaderModal>
      </div>
    </div>
  );
};

export default UserReactivation;
