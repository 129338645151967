import React, { useState, useEffect } from "react";
import "./Testimonial.css";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";

function Testimonial() {
  const [sayings, setSayings] = useState([]);
  const [current, setCurrent] = useState(0);

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/about/sayings/`,
    fetcher
  );

  useEffect(() => {
    let newArr = [];
    data &&
      data.map((say) => {
        newArr.push(say);
        return say;
      });

    data && data.length && setSayings(newArr);
  }, [data]);

  const next = () => {
    if (current < sayings.length - 1) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };

  const previous = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(sayings.length - 1);
    }
  };

  return (
    <section className="testimonial__section">
      <div className="testimonial__container">
        <div className="testimonial__opinion">
          <div className="opinion__img">
            <img src="/images/user.png" alt="user"></img>
          </div>
          {data && sayings.length && (
            <p className="opinion__text">{sayings[current].body}</p>
          )}
          {data && sayings.length && (
            <p className="opinion__name">- {sayings[current].author} -</p>
          )}
        </div>
        <div className="tetsimonial__buttons">
          <div className="testimonial__button__container">
            <button onClick={previous} className="testimonial__button">
              <i id="right" className="fa fa-caret-left"></i>
            </button>
            <p>السابق</p>
          </div>
          <div className="testimonial__button__container">
            <button onClick={next} className="testimonial__button">
              <i id="left" className="fa fa-caret-right"></i>
            </button>
            <p>التالي</p>
          </div>
        </div>
      </div>

      <img
        className="testimonial__quotes"
        src="/images/quotes.png"
        alt="quotes"></img>
    </section>
  );
}

export default Testimonial;
