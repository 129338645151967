import React from "react";
import "./Description.css";

function Description() {
  return (
    <section className="description__section">
      <div className="description__container">
        <img
          className="description__img"
          src="/images/flower.png"
          alt="flower"
        ></img>
        <p className="description">
          لقد حولت عاداتي المدمرة مثل الإفراط في تضييع الوقت بين التلفاز
          والمكالمات الغير مجديه والتذمر والخوف من المستقبل وعلى اولادي من عثرات
          الزمان ، وتناول الاكل فقط لاني ضجره،بالثقه والقليل من التمارين حولت
          إلى عادات تغذي عقلي وجسدي وروحي. والآن أنا هنا لمساعدتك على فعل
          الشيءنفسه. معا ، سنحول عاداتك ، ونطلق العنان لإمكانياتك ،ونجعلك تعيش
          حياة تفخر بها حقا
        </p>
      </div>
    </section>
  );
}

export default Description;
