import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import MyOrders from "./MyOrders";

const Page = () => {
  return (
    <div>
      <Header />
      <MyOrders />
      <Footer />
    </div>
  );
};

export default Page;
