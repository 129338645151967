import React from "react";
import useSWR, { mutate, trigger } from "swr";
import { getTheUserFromStorage } from "../../services/auth";
import { fetcher } from "../../services/fetcher";
import { deleteReservation } from "../../services/scheduling";

const MyConsultations = () => {
  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/appointments/all/?user=${
      getTheUserFromStorage().user_id
    }&offset=${new Date().getTimezoneOffset()}`,
    fetcher
  );

  const handleDelete = async (id) => {
    mutate(
      `https://mervatsreams.smartiniaclient.com/appointments/all/?user=${
        getTheUserFromStorage().user_id
      }&offset=${new Date().getTimezoneOffset()}`,
      data.filter((item) => item.id !== id),
      false
    );
    const response = await deleteReservation(id);
    console.log(response.data);
    trigger(
      `https://mervatsreams.smartiniaclient.com/appointments/all/?user=${
        getTheUserFromStorage().user_id
      }&offset=${new Date().getTimezoneOffset()}`
    );
  };

  return (
    <div
      className={`min-h-80 width-90-100 lg:width-60-100 mx-auto text-reghda-black ${
        data && data.length === 0 && "flex justify-center items-center"
      } ${data && data.length !== 0 && "my-16"}`}>
      {data &&
        data.map((item) => (
          <div
            key={item.id}
            className={`flex flex-col  md:flex-row-reverse border-1 mb-4  bg-white rounded-xl shadow-xl min-h-200 `}>
            <div className="flex-70 h-full  p-4 md:p-8 self-center text-xl md:text-2xl">
              <p className="flex flex-row-reverse">
                <span className="ml-2">: العنوان</span>
                <span>{item.session.type.title}</span>
              </p>

              <p className="flex flex-row-reverse">
                <span className="ml-2">: تاريخ</span>
                <span className="flex">
                  <span>{item.session.date}</span>
                </span>
              </p>
              <p className="flex flex-row-reverse">
                <span className="ml-2">: المدة الزمنية</span>
                <span className="flex">
                  <span className="mr-2">دقيقة</span>
                  <span>{item.session.type.duration}</span>
                </span>
              </p>
              <p className="flex flex-row-reverse">
                <span className="ml-2">: وقت البدء</span>
                <span className="flex">
                  <span className="ml-2"></span>
                  <span>{item.session.time}</span>
                </span>
              </p>
              <p className="flex flex-row-reverse">
                <span className="ml-2">: رابط الملتقى</span>
                <span className="flex">
                  <span className="ml-2"></span>
                  <span>
                    <a
                      href={item.session.meet_url}
                      className="cursor-pointer underline"
                      target="_blank"
                      rel="noreferrer">
                      إضغط هنا
                    </a>
                  </span>
                </span>
              </p>
            </div>

            <div
              className={`flex justify-center items-center min-h-full rounded-xl flex-30 p-4 text-white
              ${item.status === "ملغى" && "bg-red-400"} ${
                item.status === "جديد" &&
                new Date(
                  item.session.date.split("-")[0],
                  item.session.date.split("-")[1] - 1,
                  item.session.date.split("-")[2],
                  item.session.time.split(":")[0],
                  item.session.time.split(":")[1],
                  item.session.time.split(":")[2],
                  0
                ) >= new Date() &&
                "bg-mervet-pink"
              } 
              ${
                item.status === "جديد" &&
                new Date(
                  item.session.date.split("-")[0],
                  item.session.date.split("-")[1] - 1,
                  item.session.date.split("-")[2],
                  item.session.time.split(":")[0],
                  item.session.time.split(":")[1],
                  item.session.time.split(":")[2],
                  0
                ) <= new Date() &&
                "bg-gray-400"
              }
            `}>
              {item.status === "جديد" &&
                new Date(
                  item.session.date.split("-")[0],
                  item.session.date.split("-")[1] - 1,
                  item.session.date.split("-")[2],
                  item.session.time.split(":")[0],
                  item.session.time.split(":")[1],
                  item.session.time.split(":")[2],
                  0
                ) >= new Date() && (
                  <>
                    <p className="text-xl ">في انتظار الإستشاراة</p>
                    {/*         <button
                        onClick={() => handleDelete(item.id)}
                        className="bg-reghda-black text-pink-300 text-center w-1/2 x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg focus:outline-none hover:opacity-90">
                        حذف
                      </button> */}
                  </>
                )}
              {item.status === "ملغى" && <p className="text-xl ">ملغى</p>}
              {item.status === "جديد" &&
                new Date(
                  item.session.date.split("-")[0],
                  item.session.date.split("-")[1] - 1,
                  item.session.date.split("-")[2],
                  item.session.time.split(":")[0],
                  item.session.time.split(":")[1],
                  item.session.time.split(":")[2],
                  0
                ) < new Date() && <p className="text-xl ">تجاوز التاريخ</p>}
            </div>
          </div>
        ))}
      {data && data.length === 0 && (
        <div>
          <h1 className="text-5xl md:text-7xl text-center">
            لا توجد استشارات متاحة
          </h1>
        </div>
      )}
    </div>
  );
};

export default MyConsultations;
