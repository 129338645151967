import React from "react";
import "./PageHeader.css";

function PageHeader({ title }) {
  return (
    <div className="nabda__section">
      <p>{title}</p>
      <img src="/images/cloud_nabda.svg" alt="cloud nabda"></img>
    </div>
  );
}

export default PageHeader;
