import React, { useState, useEffect, useContext } from "react";
import { storeContext } from "../../global/store";
import Content from "../../components/Content/Content";
import LatinTitle from "../../components/LatinTitle/LatinTitle";
import "./GroupCoaching.css";
import Title from "../../components/Title/Title";
import CoachingSubscribeButton from "../../components/CoachingSubscribeButton/CoachingSubscribeButton";
import { getTheUserFromStorage } from "../../services/auth";
import { addToCart } from "../../services/cart";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import LoaderModal from "./../../components/LoaderModal";
import parse from "html-react-parser";

function GroupCoaching() {
  const { store, setStore } = useContext(storeContext);
  const { slug } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState([]);

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/courses/published/follow-up/${slug}`,
    fetcher
  );

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        `https://mervatsreams.smartiniaclient.com/students/mycourses/${data.id}`,
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setAlreadyPaid(res.data);
      return res;
    }
    store.isLogged && data && fetch();
  }, [data]);

  return (
    <div className="groupcoaching">
      <section className="groupcoaching__intro">
        <div className="groupcoaching__header">
          <div className="groupcoaching__header__leftside">
            {data && <LatinTitle content={data.title} color="#EFB7B5" />}
            {data && <Content content={parse(data.about_course)} />}
          </div>
          <div className="groupcoaching__header__rightside">
            <div className="header__img">
              <img src="/images/services_2.png" alt="some"></img>
            </div>
            <div className="header__img">
              <img src="/images/subscribe.png" alt="some"></img>
            </div>
          </div>
        </div>
        {data && <Content content={parse(data.description)} />}
      </section>
      <section className="program__definition">
        {data && <Content content={parse(data.what_you_get)} />}
      </section>
      {/* <section className="program__schedule">
        <div className="schedule__container">
          {program.map((item, index) => {
            return (
              <div className="schedule__item" key={index}>
                <p className="schedule__id">{item.number}</p>
                <Title content={item.title} color="white" />
                <Content content={item.content} color="white" />
              </div>
            );
          })}
        </div>
      </section> */}

      <section className="program__schedule">
        <div className="schedule__container">
          {data &&
            data.chapters.map((chapter, index) => {
              return (
                <div className="schedule__item" key={index + 1}>
                  <p className="schedule__id">{index + 1}</p>
                  <Title content={chapter.title} color="white" />
                  <Content
                    content={"تعرف على الاطعمه التي تمدك بالطاقه"}
                    color="white"
                  />
                </div>
              );
            })}
        </div>
      </section>

      {!store.isLogged && (
        <CoachingSubscribeButton
          action={() => {
            history.push("/login");
          }}
        />
      )}
      {store.isLogged && alreadyPaid && alreadyPaid.length === 0 && (
        <CoachingSubscribeButton
          action={async () => {
            // add to cart
            setLoading(true);
            let res = await addToCart({
              course: data.id,
              user: getTheUserFromStorage().user_id,
            });
            if (res.status === 200) {
              setLoading(false);
              history.push("/cart");
            } else {
              setLoading(false);
              alert("something wrong,retry");
            }
          }}
        />
      )}
      {store.isLogged && alreadyPaid && alreadyPaid.length > 0 && (
        <CoachingSubscribeButton
          title={"شاهد"}
          action={() => {
            history.push(`/follow-courses/course-content/${slug}`);
          }}
        />
      )}
      <LoaderModal modalIsOpen={loading || !data}></LoaderModal>
    </div>
  );
}

export default GroupCoaching;
