import React, { useState } from "react";
import Title from "./../../components/Title/Title";
import Content from "./../../components/Content/Content";
import SeeMoreButton from "./../../components/SeeMoreButton/SeeMoreButton";
import PageHeader from "./../../components/PageHeader/PageHeader";
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";

const SettingsPage = () => {
  const [image, setImage] = useState(null);
  const [date, setDate] = useState(null);
  const [name, setName] = useState(null);

  return (
    <>
      <PageHeader title="الإعدادات" />
      <div className="min-h-80 flex flex-col items-center justify-center space-y-4 pt-10">
        <div className="bg-white w-11/12 flex x-sm:flex-col md:flex-row items-center justify-around p-6">
          {/* <div
            className="cursor-pointer"
            onClick={() => {
              // document.querySelector("#upload-profile-img").click();
            }}
          >
            <Title align="right" color="#f56565" content="حذف الحساب" />
          </div> */}
          <div className="flex flex-row items-center justify-between space-x-10">
            <span>
              <Title
                color="#5C5C5C"
                content={date ? date.substring(0, 10) : ""}
              />
            </span>
            <span>
              <Title color="#C9C9C9" content="تاريخ الإشتراك" />
            </span>
          </div>
          <div className="flex x-sm:flex-col-reverse md:flex-row items-center justify-between space-x-5">
            <div className="flex flex-col items-center justify-center text-right">
              <Title
                align="right"
                color="#5C5C5C"
                content={name ? name : "إسم المستخدم"}
                font="big"
              />
              <div
                className="cursor-pointer"
                onClick={() => {
                  document.querySelector("#upload-profile-img").click();
                }}
              >
                <Title
                  align="right"
                  color="#EFB7B5"
                  content="تحميل صورة جديدة"
                />
              </div>
            </div>
            <div>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setImage(event.target.files[0]);
                  }
                }}
                id="upload-profile-img"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
              <img
                className="rounded-full x-sm:w-20 x-sm:h-20 md:w-32 md:h-32 2xl:w-36 2xl:h-36 pic-fit"
                src={
                  image
                    ? typeof image === "string"
                      ? image
                      : URL.createObjectURL(image)
                    : "/images/default-user.png"
                }
                alt="default"
              />
            </div>
          </div>
        </div>
        <div className="x-sm:w-full md:w-2/3 rounded-lg x-sm:p-6 md:p-12 2xl:p-24">
          <ProfileForm
            image={image}
            setImage={setImage}
            setDate={setDate}
            setName={setName}
          ></ProfileForm>
        </div>
        <div className="x-sm:w-full md:w-2/3 rounded-lg x-sm:p-6 md:p-12 2xl:p-24">
          <PasswordForm></PasswordForm>
        </div>
      </div>
      <section className="youtube__section">
        <img className="leaf" src="/images/leaf.png" alt="leaf"></img>
        <div className="youtube__container">
          <div className="youtube__leftside">
            <Title
              color="pink"
              content="لا تنسوا متابعتي علر قناتي باليوتيوب"
            />
            <Content
              color="white"
              content="يوجد الكثير من الاشياء في انتظارك , اشترك الان للحصول على العديد من المواد المجانية التي ستساعدك للوصول لاهدافك و التخلص من عاداتك السيئة"
            />
            <a
              href="https://www.youtube.com/channel/UC6_O8IDsc5kNRe37bMwJv2A"
              target="_blank"
              rel="noreferrer"
            >
              <SeeMoreButton content=" اشترك الان" />
            </a>
          </div>
          <div className="youtube__rightside">
            <img src="/images/youtube_pic.png" alt="youtube"></img>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingsPage;
