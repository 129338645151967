import React, { useEffect, useState } from "react";
import Content from "../../../components/Content/Content";
import PageHeader from "../../../components/PageHeader/PageHeader";
import "./Tutorials.css";
import { useHistory, useParams } from "react-router-dom";
import CourseCard from "./CourseCard";
import SubscribeSection from "../../../Sections/SubscribeSection/SubscribeSection";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import axios from "axios";
import { getTheUserFromStorage } from "../../../services/auth";
import cn from "classnames";

function CourseTier() {
  const history = useHistory();

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/courses/published/follow-up/?category=Group+Coaching`,
    fetcher
  );

  return (
    <div className="tutorials">
      <PageHeader title="تدريب جماعي" />
      <div className="tutorial__container">
        <div className="courses__container">
          {data &&
            data.length !== 0 &&
            data.map((course, i) => (
              <CourseCard key={course.id} courseDetail={course}></CourseCard>
            ))}
        </div>
      </div>
      <SubscribeSection />
    </div>
  );
}

export default CourseTier;
