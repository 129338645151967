import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CircularProgress } from "@material-ui/core";
import { paySessions } from "../../../services/payWithStripe";
import { reservateSession } from "../../../services/scheduling";
import { getTheUserFromStorage } from "../../../services/auth";
import { toast } from "react-toastify";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#fff",
      color: "#5c5c5c",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#fff" },
    },
    invalid: {
      iconColor: "#fce883",
      color: "#fce883",
    },
  },
};

export default function PaymentForm({ sessionId }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const res = await paySessions({
      session: sessionId,
    });

    const result = await stripe.confirmCardPayment(
      res.data.data.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      }
    );

    if (result.error) {
      toast(result.error.message);
      setLoading(false);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        // reservate session
        await reservateSession(sessionId, getTheUserFromStorage().user_id);
        setLoading(false);
        toast("تم الدفع بنجاح 🎉");
        history.push("/my-consultations");
      }
    }
  };

  return (
    <>
      <div className="w-full" style={{ margin: 0 }}>
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
        <button className="stripe_button" onClick={handleSubmit}>
          <span>إدفع</span>
        </button>
      </div>
      {loading && (
        <div className="flex justify-center mt-2">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
