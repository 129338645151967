import React from "react";
import "./About.css";
import CoachingItem from "../../components/CoachingItem/CoachingItem";
import PageHeader from "../../components/PageHeader/PageHeader";
import Title from "../../components/Title/Title";
import Content from "../../components/Content/Content";
import SubscribeSection from "../../Sections/SubscribeSection/SubscribeSection";
import { Link } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";

function About() {
  return (
    <div className="about">
      <PageHeader title="نبذة عني" />
      <section className="aboutme__section">
        <div className="about__container">
          <div className="about__rightside">
            <img src="/images/nabda_pic.png" alt="about me"></img>
          </div>
          <div className="about__leftside">
            <Title
              color="pink"
              content="بصفتي خبيرة ومدربة بالصحة والحياة اساعدك على
تغيير عاداتك حتى تتمكنين من تغيير حياتك للأفضل"
            />
            <br />
            <Content
              content="أنا مرڤت حسين مدربة معتمدة بالصحة والحياة لأكثر من 7 سنوات متواصلة و لقد قمت في خلال هذه الفترة الطويلة 
باعداد دورات تدريبية في كيفية تحسين صحتك الجسدية والنفسية و بناء عادات حياتية قوية"
            />
            <br />
            <Content
              content="وكيفية التغلب على مخاوفك 
والنجاح في حياتك المهنية والتعليمية , و كيف تكون اجتماعي من الدرجة الأولى و شخص مرح لديك ذكاء عاطفي واجتماعي في ادارة علاقاتك  الإجتماعية و تنمية الجانب الروحي من خلال اتصال اعمق بالخالق وتنظيم  عباداتك معه عزوجل , بالإضافة بناء خلفية عن اذارة أموالك واستثماراتك بشكل أفضل"
            />
            <br />
            <Content content="برامج شاملة لحياة أكثر اتزان قدمتها لمئات الأشخاص في أمريكا والوطن العربي سواء كانت ضمن تواصل فردي أو خلال مجموعات , أحدثت تغيراً جذرياً في حياتهم وأعمالهم  , وأصبحت حياتهم أكثر إنتاجية و سعادة ونجاح , لم اكتفي بذلك ولكن حصلت على العديد من الشهادات والدبلومات العالمية  في التغذية الوظيفية والصحة وادارة الحياة من معاهد عالمية مثل" />
            <br />
            <div className="w-full flex flex-row items-center justify-start text-left">
              <Content content="- Health Coach Institute" />
            </div>
            <div className="w-full flex flex-row items-center justify-start text-left">
              <Content content="- Functional nutrition lab" />
            </div>
          </div>
        </div>
      </section>

      <section className="knowme__section">
        <img
          src="/images/knowme_flower.png"
          id="topRightFlower"
          className="knowme__flower"
          alt="know me background"></img>
        <img
          src="/images/knowme_flower.png"
          id="bottomLeftFlower"
          className="knowme__flower"
          alt="know me background"></img>
        <img
          src="/images/knowme_bg.jpg"
          className="knowme__bg"
          alt="know me background"></img>
        <div className="knowme__container">
          <Title content="تعرف علي اكثر من خلال هذا الفديو" color="#5B5B5B" />
          <div className="knowme__video">
            <VideoPlayer url="https://mervatsreams.smartiniaclient.com/media/videos/mervat.mp4"></VideoPlayer>
            {/* <img src="/images/knowme_pic.png" alt="know me"></img> */}
            {/* <img
              src="/images/run_video.png"
              alt="run"
              className="knowme__video__play"
            ></img> */}
          </div>
        </div>
      </section>

      <section className="whyme__section">
        <div className="whyme__container">
          <Title content="لماذا انا" color="pink" font="big" />
          <Content content="هدفي هو بناء إنسان متزن من جميع النواحي في الحياة  حتى يرى العالم من أفضل منظور , إنسان على دراية كاملة ووعي كامل بذاته وقدراتة اللامحدودة , شخص فاعل ومنتج يفيد نفسه وأسرتة و مجتمعه , شخص يستطيع ادارة وتحسين صحته و عقليته وطرق تواصله مع من حوله ومع خالقه حتى يحقق الغاية من وجوده على هذا الكوكب" />
          <br />
          <Content content="من خلال سنوات التدريب و التأهيل  العلمي والعملي الذي مررت بها مكنني ذلك من إحداث تغيير كبير في حياة كل شخص تعاملت معه حتى أنني اذكر أن أحدهم أثر بي تأثيراً جليلاً  قائلا بالحرف ' كوتش مرفت أنا الحمدلله بأفضل حالاتي وأموري طيبة مع نفسي و حياتي الزوجية أنتي بمثابه طوق نجاة لشخص يغرق , تدريبكي لي أشعرني براحة وطمأنينة للقلب وشعرت بأن الدنيا بخير ' و أخرى قالت لي ' أستاذة مرفت أشكرك لوجودك بحياتي , أنتي مبدعة وجميلة , كنت تائهة ولا اعرف كيف أبدأ وماذا أنجز وما هي أولوياتي؟! الى أن وضعكي الله في طريقي , من خلالك تخلصت من التعلق بالأشخاص والأهداف التي لم تعد تخدمني وسلمت أموري لله وأنا الأن في توازن كامل ووفرة لأني أصحبت أرى الأشياء بعين مختلفة , ممتنة لكل لحظة علمتني فيها ومازلنا معا في المشوار  أحبك في الله" />
          <br />
          <Content
            color="pink"
            content="هذا الكلام يغمرني بالسعادة ويكلل تعبي ويكون وقود لإستمراري في خدمتكم ان شاء الله"
          />
        </div>
      </section>

      <section className="youcan__section">
        <div className="youcan__container">
          <div className="youcan__rightside">
            <Title content="...يمكنك ان" color="pink" font="big" />

            <div className="youcan__items">
              <div className="youcan__item">
                <i class="fa fa-check"></i>
                <Content
                  content="يمكنك اختيار قفزة الإيمان والاستثمار في أحلامك"
                  color="white"
                />
              </div>
              <div className="youcan__item">
                <i class="fa fa-check"></i>
                <Content
                  content="يمكنك اختيار الظهور بشكل مختلف والعمل للحصول على نتائج مختلفة لديك الآن"
                  color="white"
                />
              </div>
              <div className="youcan__item">
                <i class="fa fa-check"></i>
                <Content
                  content="يمكنك بناء مشروع تجاري ناجح يدور حول حياتك ، والتوقف عن عيش حياة تمليها متطلبات عملك"
                  color="white"
                />
              </div>
            </div>
          </div>
          <div className="youcan__or">
            <div>
              <p>او</p>
            </div>
          </div>
          <div className="youcan__leftside">
            <Title content="...يمكنك ان" color="pink" font="big" />
            <div className="youcan__items">
              <div className="youcan__item">
                <i class="fa fa-close"></i>
                <Content
                  content="يمكنك اختيار البقاء كما هو ومحاولة المضي قدمًا بمفردك"
                  color="white"
                />
              </div>
              <div className="youcan__item">
                <i class="fa fa-close"></i>
                <Content
                  content="يمكنك اختيار الاستمرار في تعليق أهدافك"
                  color="white"
                />
              </div>
              <div className="youcan__item">
                <i class="fa fa-close"></i>
                <Content
                  content="يمكنك اختيار الاستمرار في العمل بلا كلل ، والجري في دوائر ، والشعور بالإرهاق"
                  color="white"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="workwithme__section">
        <img
          src="/images/workwithme_img.png"
          alt="work with me"
          className="workwithme__img"></img>
        <div className="workwithme__container">
          <Title content="هذه هي فرصتك في العمل معي" color="pink" font="big" />

          <Content
            content="اختر طريقة التدريب التي تساعدك و حصل سريعا على نتائج مرضية و رائعة"
            font="big"
          />

          <div className="workwithme__services">
            <Link to="/1-1_coaching">
              <CoachingItem
                coachingType="1-1 Coaching"
                coachingImg={"/images/services_1.png"}
              />
            </Link>
            <Link to="/group-coaching-courses">
              <CoachingItem
                coachingType="group coaching"
                coachingImg={"/images/services_2.png"}
              />
            </Link>
          </div>
        </div>
      </section>

      <SubscribeSection />
    </div>
  );
}

export default About;
