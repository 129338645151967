import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { storeContext } from "../../global/store";
import { signup } from "../../services/auth";
import { toast } from "react-toastify";

const Signup = () => {
  const history = useHistory();
  const { store, setStore } = useContext(storeContext);

  const validationSchema = Yup.object({
    username: Yup.string().required("هذا الحقل مطلوب"),
    given_name: Yup.string().required("هذا الحقل مطلوب"),
    family_name: Yup.string().required("هذا الحقل مطلوب"),
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
    confirmPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .oneOf([Yup.ref("password"), null], "يجب ان تتطابق كلمات المرور"),
  });

  if (store.isLogged) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-indigo-200 h-screen w-1/2 overview-sl"></div>
      <div className="h-screen overflow-y-auto x-sm:w-full md:w-1/2 flex flex-col x-sm:space-y-2 md:space-y-3 2xl:space-y-6 text-right text-reghda-black x-sm:px-8 md:px-12 2xl:px-24 x-sm:text-sm md:text-base 2xl:text-3xl">
        <Link to="/">
          <div className="p-4 flex flex-col items-center">
            <img
              className="flex-1 x-sm:w-20 x-sm:h-20 md:w-20 md:h-20 2xl:w-48 2xl:h-48"
              alt="logo"
              src="/images/mervet_logo.png"
            />
          </div>
        </Link>
        <p className="md:text-xl 2xl:text-4xl font-extrabold">
          انشاء حساب جديد
        </p>
        <p>مرحبا بكم , يرجى ملئ جميع الحقول التالية </p>
        <Formik
          initialValues={{
            username: "",
            given_name: "",
            family_name: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const { username, given_name, family_name, password, email } =
              values;
            const res = await signup({
              email,
              password,
              username,
              given_name,
              family_name,
              auth_provider: "email",
            });
            if (res && res.data && res.status === 200) {
              toast(`${res.data.message} ✔`);
              history.push("/login");
            } else {
              alert(res.data.message);
            }
            setSubmitting(false);
          }}
        >
          <Form className="text-white x-sm:space-y-2 md:space-y-2 2xl:space-y-4">
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="إسم المستخدم"
              name="username"
              type="text"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="username" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="الإسم"
              name="given_name"
              type="text"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="given_name" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="اللقب"
              name="family_name"
              type="text"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="family_name" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="البريد الالكتروني"
              name="email"
              type="email"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="email" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="كلمة السر"
              name="password"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="password" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="تاكيد كلمة السر"
              name="confirmPassword"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="confirmPassword" />
            </div>
            <button
              className="bg-reghda-black text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
              type="submit"
            >
              سجل معنا
            </button>
          </Form>
        </Formik>
        <p>
          بتسجيلك على الموقع أنت توافق على
          <span className="text-pink-200"> شروط الخدمة و سياسة الخصوصية</span>
        </p>
        <Link to="/login">
          لديك حساب ؟ <span className="text-pink-200">سجل الدخول</span>
        </Link>
      </div>
    </div>
  );
};

export default Signup;
