import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import "./Contact.css";
import Title from "../../components/Title/Title";
import Content from "../../components/Content/Content";

function Contact() {
  return (
    <div className="contact">
      <PageHeader title="تواصل معي" />

      <section className="contact__section">
        <div className="contact__leftside">
          <form>
            <div className="field">
              <label htmlFor="firstName">
                <Content content=": الاسم" />{" "}
              </label>
              <input type="text" name="firstName" id="firstName" />
            </div>
            <div className="field">
              <label htmlFor="lastName">
                <Content content=": اللقب" />
              </label>
              <input type="text" name="lastName" id="lastName" />
            </div>
            <div className="field">
              <label htmlFor="email">
                <Content content=": البريد الالكتروني" />
              </label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">
                <Content content=": رسالتك" />
              </label>
              <textarea type="text" name="message" id="message" />
            </div>

            <div className="contact__service__type">
              <Content content=": طريقة العمل التي تريدها" />
              <div className="service__types">
                <div className="field">
                  <label htmlFor="email">
                    <Content content="1-1 Coaching" />
                  </label>
                  <input type="checkbox" name="email" id="email" />
                </div>
                <div className="field">
                  <label htmlFor="email">
                    <Content content="Group Coaching" />
                  </label>
                  <input type="checkbox" name="email" id="email" />
                </div>
              </div>
            </div>

            <div className="field">
              <input type="submit" value="ارسل" />
            </div>
          </form>
        </div>
        <div className="contact__rightside">
          <img src="/images/contact-picture.png" alt="contact"></img>
          <Title content="سعيدة لتواصلك معي" />
          <Content content="إذا كنت مهتما بالعمل معا، فيرجى إكمال النموذج. سنتواصل معك في غضون 24 ساعة." />
          <div className="socialmedia__icons">
            <a
              href="https://www.youtube.com/channel/UC6_O8IDsc5kNRe37bMwJv2A"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/youtube.png" alt="inst"></img>
            </a>
            <a
              href="https://www.instagram.com/mervat4444/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/instagram.svg" alt="inst"></img>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=13144771253&text=Health%20and%20life%20coach%20"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/whatsapp.svg" alt="inst"></img>
            </a>
            <a
              href="https://www.facebook.com/mervatcare/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/facebook.svg" alt="inst"></img>
            </a>
            <a
              href="mailto:mervatstream@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/email.svg" alt="inst"></img>
            </a>
            {/* <img src="/images/instagram.svg" alt="inst"></img>
            <img src="/images/whatsapp.svg" alt="inst"></img>
            <img src="/images/facebook.svg" alt="inst"></img>
            <img src="/images/email.svg" alt="inst"></img> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
