import React from "react";
import "./SeeMoreButton.css";

function SeeMoreButton({ content, action }) {
  return (
    <button onClick={action} className="readmore__btn">
      <i class="fa fa-long-arrow-left"></i>
      {content}
    </button>
  );
}

export default SeeMoreButton;
