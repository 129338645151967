import React from "react";
import "./HomeFirstSection.css";

function HomeFirstSection() {
  const handleSubscribeModal = () => {
    var modal = document.getElementById("subscribeModal");
    modal.classList.add("visible");
  };

  return (
    <section className="first__section">
      <div className="home__img">
        <img src="/images/home_picture.png" alt="mervet home"></img>
      </div>

      <div className="subscribe__box">
        <div className="subscribe__cloud">
          <img src="/images/cloud.png" alt="cloud"></img>
        </div>

        <p className="subscribe__title">Life and Health Coach</p>
        <p className="subscribe__description">
          بصفتي خبيرة ومدربة بالصحة والحياة اساعدك على تغيير عاداتك حتى تتمكنين
          من تغيير حياتك للأفضل
        </p>

        <button
          className="subscribe__button"
          onClick={() => {
            handleSubscribeModal();
          }}
        >
          اشترك معنا
        </button>
      </div>
    </section>
  );
}

export default HomeFirstSection;
