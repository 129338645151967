import Axios from "axios";

export async function addComment(data) {
  try {
    const response = await Axios.post(
      `https://mervatsreams.smartiniaclient.com/comments/all/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteComment(commentId) {
  try {
    const response = await Axios.delete(
      `https://mervatsreams.smartiniaclient.com/comments/all/${commentId}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function editComment({ commentId, newComment }) {
  try {
    const response = await Axios.patch(
      `https://mervatsreams.smartiniaclient.com/comments/all/${commentId}/`,
      { body: newComment }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function addResponse({ user, parent, body }) {
  try {
    const response = await Axios.post(
      `https://mervatsreams.smartiniaclient.com/comments/answers/`,
      {
        user,
        parent,
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editResponse({ responseId, body }) {
  try {
    const response = await Axios.patch(
      `https://mervatsreams.smartiniaclient.com/comments/answers/${responseId}/`,
      {
        body,
      }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function deleteResponse(responseId) {
  try {
    const response = await Axios.delete(
      `https://mervatsreams.smartiniaclient.com/comments/answers/${responseId}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
