import React from 'react'
import "./LatinTitle.css"
function LatinTitle({content , fontSize , color}) {
    return (
        <p className={fontSize === 'big' ? "latintitle latintitle__big" : "latintitle"} 
            style={{color : color ? color : "white"}}
        >{content}</p>
    )
}

export default LatinTitle
