import Axios from "axios";

export async function updateProfile(id, data) {
  try {
    const response = await Axios.patch(
      `https://mervatsreams.smartiniaclient.com/users/profile/${id}`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function updatePassword(data) {
  try {
    const response = await Axios.patch(
      `https://mervatsreams.smartiniaclient.com/users/password/update`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
