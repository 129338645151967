import React, { useEffect, useContext, useRef } from "react";
import "./Header.css";
import { useHistory, Link } from "react-router-dom";
import { storeContext } from "./../../global/store";
import { deleteTheUserAndTokenFromStorage } from "./../../services/auth";

// import { IconContext } from "react-icons";
// import { AiFillSetting } from "react-icons/ai";
// import { FiLogOut } from "react-icons/fi";
// import { FaChalkboardTeacher, FaListAlt } from "react-icons/fa";
// import { TiShoppingCart } from "react-icons/ti";
// import { CgProfile } from "react-icons/cg";

function Header() {
  const { store, setStore } = useContext(storeContext);
  const history = useHistory();
  const DropRef = useRef();

  const openMenu = () => {
    var menu = document.getElementById("menu");
    menu.classList.toggle("open");
    menu.classList.toggle("menu__bar");
  };
  const closeMenu = () => {
    var menu = document.getElementById("menu");
    if (menu.classList.value === "open") {
      menu.classList.toggle("open");
      menu.classList.toggle("menu__bar");
    }
  };

  /*   const escFunction = (event) => {
    if (event.keyCode === 27) {
      var menu = document.getElementById("menu");
      if (menu.classList.contains("open")) {
        menu.classList.toggle("open");
        menu.classList.toggle("menu__bar");
      }
    }
  };

  const outsideClickListener = (event) => {
    if (pathname.includes("login") || pathname.includes("signup")) {
      return;
    }

    const target = event.target;
    var menu = document.getElementById("menu");
    var bars = document.getElementsByClassName("fa-bars")[0];
    if (!target.closest("[id='menu']") && menu.classList.contains("open")) {
      menu.classList.toggle("open");
      menu.classList.toggle("menu__bar");
    }
  }; */

  function handleClickOutside(event) {
    if (DropRef.current && !DropRef.current.contains(event.target)) {
      closeMenu();
    }
  }

  const escFunction = (event) => {
    if (DropRef.current && !DropRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", escFunction, false);
    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", escFunction, false);
    };
  }, []);

  /*   useEffect(() => {
    document.addEventListener("click", outsideClickListener);
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("click", outsideClickListener);
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []); */

  /*   if (pathname.includes("login") || pathname.includes("signup")) {
    return null;
  } */

  const handleRoute = (route) => {
    openMenu();
    history.push(route);
  };

  return (
    <div className="header">
      <div id="menu" className="menu__bar" ref={DropRef}>
        <i onClick={() => openMenu()} class="fa fa-bars"></i>
        <div className="menu__content">
          <ul>
            <li onClick={() => handleRoute("/")}>الرئيسية</li>
            <li onClick={() => handleRoute("/about")}>نبذة عني</li>
            <li onClick={() => handleRoute("/tutorials/all-courses")}>دورات</li>
            <li onClick={() => handleRoute("/consultations")}>إستشارات</li>
            <li onClick={() => handleRoute("/blog")}>مدونتي</li>
            <li onClick={() => handleRoute("/articles")}>مقالات</li>
            <li id="work__with__me">
              <div>
                اعمل معي <i class="fa fa-caret-right"></i>
              </div>
              <ul>
                <li onClick={() => handleRoute("/1-1_coaching")}>
                  1-1 Coaching
                </li>
                <li onClick={() => handleRoute("/group-coaching-courses")}>
                  Group Coaching
                </li>
              </ul>
            </li>

            <li onClick={() => handleRoute("/contact")}>تواصل معي</li>
            <li onClick={() => handleRoute("/privacy-policy")}>
              سياسة الخصوصية
            </li>

            {store.isLogged && store.user && (
              <li id="work__with__me">
                <div>
                  الملف الشخصي
                  <i class="fa fa-caret-right"></i>
                </div>
                <ul>
                  <li onClick={() => handleRoute("/settings")}>إعدادات</li>
                  <li onClick={() => handleRoute("/my-courses")}>دوراتي </li>
                  <li onClick={() => handleRoute("/my-consultations")}>
                    إستشاراتي{" "}
                  </li>
                  <li onClick={() => handleRoute("/my-orders")}>طلباتي </li>
                </ul>
              </li>
            )}
            {/* <li onClick={() => handleRoute("/my-courses")}>دوراتي</li> */}
            {store.isLogged && (
              <li onClick={() => handleRoute("/cart")}>السلة</li>
            )}
            {store.isLogged && store.user && store.user.is_staff && (
              <li className="text-reghda-black">
                <a
                  href="https://admin.mervatstreams.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  لوحة التحكم
                </a>
              </li>
            )}
          </ul>
          <div className="menu__bar__buttons">
            {!store.isLogged && (
              <button onClick={() => handleRoute("/login")}>
                تسجيل الدخول
              </button>
            )}
            {!store.isLogged && (
              <button onClick={() => handleRoute("/signup")}>
                مستخدم جديد
              </button>
            )}
            {store.isLogged && (
              <button
                onClick={() => {
                  setStore({
                    ...store,
                    isLogged: false,
                  });
                  deleteTheUserAndTokenFromStorage();
                }}
              >
                تسجيل الخروج
              </button>
            )}
          </div>

          <div className="socialmedia__icons">
            <a
              href="https://www.youtube.com/channel/UC6_O8IDsc5kNRe37bMwJv2A"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/youtube_black.png" alt="inst"></img>
            </a>
            <a
              href="https://www.instagram.com/mervat4444/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/instagram_black.svg" alt="inst"></img>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=13144771253&text=Health%20and%20life%20coach%20"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/whatsapp_black.svg" alt="inst"></img>
            </a>
            <a
              href="https://www.facebook.com/mervatcare/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/facebook_black.svg" alt="inst"></img>
            </a>
            <a
              href="mailto:mervatstream@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/images/email_black.svg" alt="inst"></img>
            </a>
          </div>
        </div>
      </div>

      <Link to="/" className="logo">
        <img src="/images/mervet_logo.png" alt="logo"></img>
      </Link>
    </div>
  );
}

export default Header;
