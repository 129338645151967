import React, { useRef } from "react";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const VideoPlayer = ({ url, type, next }) => {
  const playerRef = useRef(null);

  return (
    <div className="w-full flex flex-col space-y-4 ">
      {type === "audio" && (
        <AudioPlayer
          autoPlay={true}
          autoPlayAfterSrcChange={true}
          src={url}
          // showJumpControls={false}
          // onPlay={(e) => console.log("onPlay")}
          // other props here
        />
      )}
      {type === "video" && (
        <div className="relative" style={{ paddingTop: "56.25%" }}>
          <ReactPlayer
            height="100%"
            width="100%"
            className="absolute top-0 left-0"
            ref={playerRef}
            // muted={true}
            playing={false}
            controls={true}
            url={url}
            onEnded={() => {
              // next();
              // alert("video ended right now 🎁");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
