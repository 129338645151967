import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Content from "../../../components/Content/Content";
import parse from "html-react-parser";

const CourseCard = ({ courseDetail }) => {
  const history = useHistory();
  return (
    <div className="course__item">
      <div className="course__img">
        <img src={courseDetail.picture} alt="course"></img>
      </div>
      <div className="course__content">
        <p className="course__title">{courseDetail.title}</p>
        <p className="course__description">
          <Content content={parse(courseDetail.description)} />
        </p>
      </div>

      <div className="course__subscribe">
        <button
          onClick={() => {
            history.push(`/one-one-coaching/${courseDetail.slug}`);
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <p>تفاصيل</p>
            <i class="fa fa-long-arrow-left"></i>
          </div>
        </button>
      </div>
    </div>
  );
};

export default CourseCard;
