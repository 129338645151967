import Axios from "axios";

export async function updateVisit(user_id, data) {
  try {
    const response = await Axios.patch(
      `https://mervatsreams.smartiniaclient.com/subscriptions/follow-up/check/visits?user=${user_id}&category=1-1+Coaching`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
