import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import LoaderModal from "../../components/LoaderModal";
import PayButton from "./PayButton";
import StripeButton from "./StripeButton";
import ApplePay from "./ApplePay";

const CheckOut = () => {
  const history = useHistory();
  const courseIds = history.location.state.data.courseIds;
  const courses = history.location.state.data.courses;
  const total = history.location.state.data.total;
  const cartId = history.location.state.data.cartId;

  const [loading, setLoading] = useState(false);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          <p className="border-b-2 border-reghda-blue pb-4 text-reghda-blue text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
            المشتريات
          </p>
          {courses &&
            courses.length > 0 &&
            courses.map((course, i) => (
              <div
                key={i}
                className="flex flex-row items-center justify-between py-4">
                <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  {`${course.price} $`}
                </p>
                <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  {`${course.title}`}
                </p>
              </div>
            ))}
          <div className="flex flex-row items-center justify-between py-4">
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              {`${total} $`}
            </p>
            <p className="text-reghda-pink text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              المجموع
            </p>
          </div>
        </div>
      </div>
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          <p className="pb-4 text-reghda-blue text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
            الدفع
          </p>
          <div className="flex flex-row items-center justify-between py-4">
            <div className="flex flex-row space-x-4 items-center justify-center">
              <img
                src="/images/footer/visa.png"
                className="x-sm:h-6 sm:h-10"
                alt="icon"
              />
              <img
                src="/images/footer/mastercard.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
            </div>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الدفع عبر
            </p>
          </div>
          <StripeButton cartId={cartId} setLoading={setLoading}></StripeButton>

          <ApplePay setLoading={setLoading} total={total} cartId={cartId} />
          <br />
          <br />
          <div className="flex flex-row items-center justify-between py-4">
            <div className="flex flex-row space-x-4 items-center justify-center">
              <img
                src="/images/footer/paypal.png"
                className="x-sm:h-3 sm:h-5"
                alt="icon"
              />
            </div>
            <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الدفع عبر
            </p>
          </div>
          <PayButton
            setLoading={setLoading}
            total={total}
            ids={courseIds}></PayButton>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CheckOut;
