import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import Title from "../../components/Title/Title";

import { IconContext } from "react-icons";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { getTheUserFromStorage } from "./../../services/auth";
import { subscribe } from "../../services/followCourses";
import LoaderModal from "../../components/LoaderModal";

const ChapterItems = ({
  setWaiting,
  setCanUnlock,
  courseId,
  category,
  setCurrentChapter,
  currentChapter,
  setupCurrent,
  chapters,
  currentModule,
  setCurrentModule,
}) => {
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/subscriptions/follow-up/details/${
      getTheUserFromStorage().user_id
    }/${courseId}`,
    fetcher
  );

  const [chapterState, SetChaptersState] = useState([]);
  const [modulesState, SetModulesState] = useState([]);

  useEffect(() => {
    let chapters = [];
    data &&
      data.details.map((item) => {
        chapters.push({ id: item.chapter.id, status: item.status });
        return item;
      });
    SetChaptersState(chapters);

    let modules = [];
    data &&
      data.details.map((item) => {
        item.modules.map((module) => {
          modules.push({
            id: module.id,
            status: module.status,
          });
          return module;
        });
        return item;
      });
    SetModulesState(modules);
    //unlock
    data && setCanUnlock(data.can_unlock);
    // waiting
    data && setWaiting(data.waiting);
  }, [data]);

  const lookup = (type, id) => {
    if (type === "chapter") {
      return chapterState.filter((chapter) => chapter.id === id)[0].status;
    } else if (type === "module") {
      return modulesState.filter((module) => module.id === id)[0].status;
    }
  };

  return (
    <div className="flex flex-col w-full">
      {!data && courseId && (
        <button
          onClick={async () => {
            setLoading(true);
            const res = await subscribe(
              category === "Group Coaching" ? "group" : "one-one",
              {
                user: getTheUserFromStorage().user_id,
                course: courseId,
              }
            );
            mutate(
              `https://mervatsreams.smartiniaclient.com/subscriptions/follow-up/details/${
                getTheUserFromStorage().user_id
              }/${courseId}`
            );
            setLoading(false);
          }}
          className="w-full bg-reghda-blue text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
          إبدا
        </button>
      )}
      {data &&
        chapterState.length > 0 &&
        modulesState.length > 0 &&
        chapters.map((chapter, i) => {
          return (
            <Collapsible
              triggerDisabled={
                lookup("chapter", chapter.id) === "مغلق" ||
                lookup("chapter", chapter.id) === "في الإنتظار"
              }
              trigger={
                lookup("chapter", chapter.id) === "مفتوح" ||
                lookup("chapter", chapter.id) === "مكتمل" ? (
                  <div className="w-full flex flex-row items-center justify-between space-x-4 bg-reghda-black border-2 border-solid border-pink-200 text-white rounded-lg x-sm:p-1 md:p-3 2xl:p-6 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                    <div className="">
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#9ae6b4" } }}>
                        <BsFillUnlockFill></BsFillUnlockFill>
                      </IconContext.Provider>
                    </div>
                    <div className="flex flex-row items-center justify-end space-x-2">
                      <Title content={chapter.title} color="white" />
                      <i class="fa fa-arrow-circle-down"></i>
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex flex-row items-center justify-between space-x-4 bg-reghda-black border-2 border-solid border-pink-200 text-white rounded-lg x-sm:p-1 md:p-3 2xl:p-6 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                    <div className="">
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#f56565" } }}>
                        <BsFillLockFill></BsFillLockFill>
                      </IconContext.Provider>
                    </div>
                    <div className="flex flex-row items-center justify-end space-x-2">
                      <Title content={chapter.title} color="white" />
                      <i class="fa fa-arrow-circle-down"></i>
                    </div>
                  </div>
                )
              }
              className="text-right x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-y-0"
              openedClassName="text-right x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-y-0">
              {chapter.modules.map((module, idx) => {
                return (
                  <p className="flex flex-row items-center justify-between x-sm:p-0 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                    <div className="">
                      {lookup("module", module.id) === "مفتوح" ? (
                        <IconContext.Provider
                          value={{ size: "1em", style: { color: "#9ae6b4" } }}>
                          <BsFillUnlockFill></BsFillUnlockFill>
                        </IconContext.Provider>
                      ) : (
                        <IconContext.Provider
                          value={{ size: "1em", style: { color: "#f56565" } }}>
                          <BsFillLockFill></BsFillLockFill>
                        </IconContext.Provider>
                      )}
                    </div>
                    <p
                      onClick={() => {
                        if (lookup("module", module.id) === "مفتوح") {
                          setCurrentChapter(chapter.id);
                          setCurrentModule(module.id);
                          setupCurrent(chapter.id, module.id);
                        }
                      }}
                      key={idx}
                      className={
                        module.id === currentModule
                          ? "text-mervet-pink p-2 cursor-pointer"
                          : "text-gray-600 p-2 cursor-pointer"
                      }>
                      {module.title}
                    </p>
                  </p>
                );
              })}
            </Collapsible>
          );
        })}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default ChapterItems;
