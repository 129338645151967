import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Content from "../../components/Content/Content";
import parse from "html-react-parser";
import axios from "axios";
import { getTheUserFromStorage } from "../../services/auth";
import { storeContext } from "../../global/store";

const CourseCard = ({ courseDetail }) => {
  const history = useHistory();
  const [alreadyPaid, setAlreadyPaid] = useState([]);
  const { store } = useContext(storeContext);

  console.log(courseDetail);

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        `https://mervatsreams.smartiniaclient.com/students/mycourses/${courseDetail.id}`,
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setAlreadyPaid(res.data);

      return res;
    }
    courseDetail && store.isLogged && fetch();
  }, [courseDetail]);

  return (
    <div className="course__item">
      <div className="course__img">
        <img src={courseDetail.picture} alt="course"></img>
      </div>
      <div className="course__content">
        <p className="course__title">{courseDetail.title}</p>
        <p className="course__description">
          <Content content={parse(courseDetail.description)} />
        </p>
        {courseDetail.price && (
          <p className="course__price">
            الثمن :{" "}
            {courseDetail?.discounted_price && (
              <span>{courseDetail?.discounted_price} دولار</span>
            )}
            <span
              className={`${
                courseDetail?.discounted_price && "line-through text-gray-300"
              }`}>
              {" "}
              {courseDetail.price} دولار
            </span>{" "}
          </p>
        )}
      </div>

      <div className="course__subscribe">
        <button
          onClick={() => {
            if (
              courseDetail.type === "paid" ||
              courseDetail.type === "follow-up"
            ) {
              history.push(
                `/overview/${courseDetail.type}/${courseDetail.slug}`
              );
            } else {
              history.push(`/course-content/${courseDetail.slug}`);
            }
          }}>
          {store.isLogged && alreadyPaid && alreadyPaid.length === 0 && (
            <div className="flex flex-col justify-center items-center">
              <p>تفاصيل</p>
              <i class="fa fa-long-arrow-left"></i>
            </div>
          )}

          {!store.isLogged && (
            <div className="flex flex-col justify-center items-center">
              <p>تفاصيل</p>
              <i class="fa fa-long-arrow-left"></i>
            </div>
          )}
          {store.isLogged && alreadyPaid && alreadyPaid.length > 0 && (
            <div className="flex flex-col justify-center items-center">
              <p>اذهب إلى دورتي</p>
              <i class="fa fa-long-arrow-left"></i>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default CourseCard;
