import React, { useEffect, useState } from "react";
import Content from "../../components/Content/Content";
import PageHeader from "../../components/PageHeader/PageHeader";
import "./Tutorials.css";
import SubscribeSection from "../../Sections/SubscribeSection/SubscribeSection";
import { useHistory, useParams } from "react-router-dom";
import CourseCard from "./CourseCard";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";

import cn from "classnames";
import LoaderModal from "../../components/LoaderModal";

function Tutorials() {
  const history = useHistory();

  const [active, setActive] = useState("all");
  const [coursesUrl, setCoursesUrl] = useState(
    "https://mervatsreams.smartiniaclient.com/courses/published/all"
  );

  const { data, error } = useSWR(coursesUrl, fetcher);

  const allStyle = cn("cursor-pointer");
  const freeStyle = cn("cursor-pointer");
  const paidStyle = cn("cursor-pointer");

  return (
    <div className="tutorials">
      <PageHeader title="دورات" />
      <div className="tutorial__container">
        <div className="tutorials__navbar">
          <ul>
            <li
              onClick={() => {
                setCoursesUrl(
                  "https://mervatsreams.smartiniaclient.com/courses/published/all"
                );
                mutate(
                  "https://mervatsreams.smartiniaclient.com/courses/published/all"
                );
                setActive("all");
              }}
              className={allStyle}>
              {active === "all" ? (
                <Content color="#efb7b5" content="الكل" />
              ) : (
                <Content content="الكل" />
              )}
            </li>
            <li
              onClick={() => {
                setCoursesUrl(
                  "https://mervatsreams.smartiniaclient.com/courses/published/free"
                );
                mutate(
                  "https://mervatsreams.smartiniaclient.com/courses/published/free"
                );
                setActive("free");
              }}
              className={freeStyle}>
              {active === "free" ? (
                <Content color="#efb7b5" content="الدورات المجانية" />
              ) : (
                <Content content="الدورات المجانية" />
              )}
            </li>
            <li
              onClick={() => {
                setCoursesUrl(
                  "https://mervatsreams.smartiniaclient.com/courses/published/paid"
                );
                mutate(
                  "https://mervatsreams.smartiniaclient.com/courses/published/paid"
                );
                setActive("paid");
              }}
              className={paidStyle}>
              {active === "paid" ? (
                <Content color="#efb7b5" content="الدورات المدفوعة" />
              ) : (
                <Content content="الدورات المدفوعة" />
              )}
            </li>
          </ul>
        </div>
        <div className="courses__container">
          {data &&
            data.map((course, i) => (
              <CourseCard courseDetail={course}></CourseCard>
            ))}
        </div>
      </div>
      <SubscribeSection />
      <LoaderModal modalIsOpen={!data && !error}></LoaderModal>
    </div>
  );
}

export default Tutorials;
