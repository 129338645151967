import React from "react";
import "./Title.css";
function Title({ content, color, font, align }) {
  return (
    <p
      style={{
        color: color === "pink" ? "#EFB7B5" : color,
        textAlign: align ? align : "right",
      }}
      className={font === "big" ? "title big" : "title"}
    >
      {content}
    </p>
  );
}

export default Title;
