import React from "react";
import Content from "../Content/Content";
import Title from "../Title/Title";
import "./SubscribeForm.css";
import { useLocation } from "react-router-dom";

function SubscribeForm() {
  const { pathname } = useLocation();

  const handleSubscribeModal = () => {
    var modal = document.getElementById("subscribeModal");
    modal.classList.remove("visible");
  };

  return (
    <div className="subscribe__content">
      {pathname === "/" && (
        <div
          onClick={() => {
            handleSubscribeModal();
          }}
          className="self-start cursor-pointer">
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      )}
      <div className="subscribe__title">
        <Title
          content="الكثير في انظارك اشترك الان و لا تفوت الفرصة"
          color="pink"
        />
      </div>
      <div className="subscribe__description">
        <Content content="يوجد الكثير من الاشياء في انتظارك , اشترك الان للحصول على العديد من المواد المجانية التي ستساعدك للوصول لاهدافك و التخلص من عاداتك السيئة" />
      </div>

      <div className="subscribe__form">
        <form>
          <input type="email" placeholder="...بريدك الالكتروني"></input>
          <input type="submit" value="اشترك الان"></input>
        </form>
      </div>
    </div>
  );
}

export default SubscribeForm;
