import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Header></Header>
      <div className="h-full font-ElMessiri text-center p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600">
        <h1 className="font-extrabold x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          سياسة الخصوصية
        </h1>
        <div className="text-right x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl leading-loose space-y-6">
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            مقدمة
          </h1>
          <p className="font-medium leading-loose break-words">
            مرحبًا بك في موقع ميرفيت ستريمس الإلكتروني . تعود ملكية هذا الموقع
            وتشغيله إلى ميرفيت ستريمس للتدريب يرجى قراءة شروط الاستخدام هذه
            بعناية قبل استخدام الموقع. باستخدامك لهذا الموقع ، فهذا يعني موافقتك
            على شروط الاستخدام هذه. إذا كنت لا توافق على شروط الاستخدام هذه ،
            فلا يجوز لك استخدام الموقع. ثانيا، عند استخدام أي من خدماتنا الحالية
            أو المستقبلية ، ستخضع أيضًا لإرشاداتنا وشروطنا وشروطنا واتفاقياتنا
            المعمول بها على تلك الخدمات. إذا كانت شروط الاستخدام هذه غير متسقة
            مع الإرشادات والشروط والاتفاقيات المطبقة على هذه الخدمات ، فإن شروط
            الاستخدام هذه ستكون هي المرجع
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            خصوصية حسابك
          </h1>
          <p className="font-medium leading-loose break-words">
            يرجى مراجعة سياسة الخصوصية الخاصة بنا ، والتي تحكم أيضًا في استخدامك
            لهذا الموقع، لفهم أحكام الخصوصية لدينا. كل الدورات والمواد على
            موقعنا ميرفيت ستريمس للتدريب هي موجهة للبالغين الذين يمكنهم الشراء
            باستخدام بطاقة ائتمان أو أي طريقة دفع أخرى مسموح بها. إذا كان عمرك
            أقل من 18 عامًا ، فلا يجوز لك استخدام الموقع إلا بمشاركة أحد
            الوالدين أو الوصي. نحتفظ بالحق في رفض الخدمة أو إنهاء الحسابات أو
            إزالة المحتوى أو تحريره أو إلغاء الطلبات وفقًا لتقديرنا الخاص
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            قيود الاستخدام، ترخيص محدود
          </h1>
          <p className="font-medium leading-loose break-words">
            جميع المحتويات الموجودة على الموقع (مجتمعة ، "المحتوى") ، مثل النصوص
            والرسومات والشعارات والرموز والصور ومقاطع الصوت والفيديو والتنزيلات
            الرقمية وتجميع البيانات والبرامج هي ملك لنا، وتجميع المحتوى على
            الموقع هو ملك حصري لنا نحن موقع ميرفيت ستريمس للتدريب المحمي بموجب
            قوانين ومعاهدات واتفاقيات حقوق النشر الدولية. جميع البرامج المستخدمة
            على الموقع هي ملكنا أو ملك لموردي البرامج لدينا ومحمية بموجب قوانين
            ومعاهدات واتفاقيات حقوق النشر الدولية. إن أي علامات تجارية وعلامات
            خدمة ورسومات وشعارات ورؤوس صفحات وأيقونات ونصوص وأسماء تجارية (كل
            منها "علامة") موجودة على الموقع الإلكتروني هي ملكية لنا أو لمرخصينا
            أو المرخص لهم. لا يجوز استخدام علاماتنا فيما يتعلق بأي منتج أو خدمة
            ليست لنا بأي شكل من الأشكال التي من المحتمل أن تسبب ارتباكًا
            للمستخدمين أو التي قد تسيء إلينا أو تقلل من شأننا أو أي شخص آخر.
            جميع العلامات التجارية الأخرى التي لا نملكها والتي تظهر على الموقع
            الإلكتروني هي ملك لأصحابها ، الذين قد يكونون تابعين لنا أو غير
            مرتبطين بنا أو مرتبطين بنا. نحن نمنحك ترخيصًا محدودًا للوصول إلى
            الموقع واستخدامه الشخصي. ولا يجوز لك نسخ أو إعادة إنتاج أو إعادة نشر
            أو تنزيل أي محتوى أو نشره أو نقله أو توزيعه بأي شكل من الأشكال، أو
            بيعه أو استغلاله لأي غرض تجاري. باستثناء أنه يمكنك تنزيل نسخة واحدة
            (1) من المحتوى الذي نوفره لك لهذه الأغراض على جهاز كمبيوتر واحد
            لاستخدامك الشخصي غير التجاري للاستخدام الشخصي فقط ، شريطة أن: (أ)
            تحافظ على جميع إشعارات حقوق النشر والعلامات التجارية وحقوق الملكية
            الأخرى سليمة ؛ (ب) عدم القيام بأي تعديل على المحتوى؛ (ج) عدم استخدام
            أي محتوى خاص بك بطريقة توحي بالارتباط نع من منتج من منتجاتنا أو
            خدماتنا أو علاماتنا التجارية؛ (د) يُحظر تمامًا استخدامك لمحتوى
            موقعنا ميرفيت ستريمس للتدريب على أي موقع ويب أو بيئة كمبيوتر أخرى
            بدون الحصول على إذن كتابي موقع من طرف الأستاذة ميرفيت . لا يشمل
            الترخيص الممنوح لك ، ويستبعد على وجه التحديد ، أي حقوق في: إعادة بيع
            أو استخدام أي استخدام تجاري للموقع أو أي محتوى ؛ جمع واستخدام أي
            قوائم منتجات أو أوصاف أو أسعار؛ أي استخدام مشتق للموقع أو المحتوى؛
            تنزيل أو نسخ معلومات الحساب لصالح أي شخص آخر؛ أو استخدام أي شكل من
            أشكال استخراج البيانات أو الروبوتات أو أدوات جمع البيانات واستخراجها
            المماثلة. لا يجوز لك تأطير أو استخدام تقنيات الإطارات لإرفاق أي
            علامة أو محتوى أو معلومات ملكية أخرى ، أو استخدام أي علامات وصفية أو
            أي "نص مخفي" آخر باستخدام أي ملكية فكرية من هذا القبيل ، دون موافقة
            خطية صريحة. يؤدي أي استخدام غير مصرح به إلى إنهاء الترخيص الممنوح لك
            بموجب هذه الاتفاقية تلقائيًا
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            استخدام وحماية كلمة المرور والهوية
          </h1>
          <p className="font-medium leading-loose break-words">
            كل مشترك بالموقع لديه معرف وكلمة مرور خاصة به، حتى يتمكن من الوصول
            إلى مناطق معينة من الموقع واستخدامها. يُعتبر كل مستخدم يستخدم كلمة
            المرور والمعرف الخاصين به مصرحًا له بالوصول إلى محتويات الموقع
            واستخدامه. أنت تقر وتوافق على أنك ستكون مسؤولاً وحدك عن كل وصول إلى
            الموقع واستخدامه من قبل شخص آخر استخدم كلمة المرور والمعرف الخاصين
            بك، بما في ذلك جميع الاتصالات جميع الالتزامات بما فيها الالتزامات
            المالية لعمليات الشراء عبر الموقع الإلكتروني). أنت وحدك المسؤول عن
            حماية أمان وسرية كلمة المرور والمعرف الخاصين بك. يجب عليك على الفور
            إخطار فريق الدعم الفني في حال الاشتباه بأي استخدام غير مصرح به لكلمة
            المرور أو المعرّف الخاصين بك، أو أي خرق آخر أو تهديد خرق لأمان موقع
            الويب الذي تعرفه. ستكون مسؤولاً عن أي نشاط يتم إجراؤه باستخدام كلمة
            المرور أو المعرّف المخصص لك
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            متطلبات النظام
          </h1>
          <p className="font-medium leading-loose break-words">
            يتطلب استخدام مناطق معينة من موقع الويب الوصول إلى الإنترنت. يجوز
            لـنا في أي وقت وفقًا لتقديرنا الخاص ، تعديل أو مراجعة أو تغيير أو
            تغيير متطلبات النظام لموقع ميرفيت ستريمس للتدريب وتنسيق أي محتوى
            قابل للتنزيل ، كليًا أو جزئيًا ، دون إشعار المشتركين
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            المراسلات والمنشورات
          </h1>
          <p className="font-medium leading-loose break-words">
            يمكنك نشر التعليقات والصور وغيرها من المحتويات؛ إرسال اقتراحات أو
            أفكار أو تعليقات أو أسئلة أو معلومات أخرى ، طالما لم تكن أي من هذه
            المواد غير قانونية أو فاحشة أو مهددة أو تشهيرية أو تتضمن اعتداء على
            الخصوصية أو تنتهك حقوق الملكية الفكرية أو تلحق الأذى بأطراف ثالثة أو
            مرفوضة. كما لا يجب أن تحتوي على فيروسات برمجية أو حملات سياسية أو
            استدراج تجاري أو رسائل متسلسلة أو رسائل جماعية أو أي شكل من أشكال
            "البريد العشوائي". لا يجوز لك استخدام عنوان بريد إلكتروني زائف أو
            انتحال صفة أي شخص أو تضليل مصدر أي محتوى. نحتفظ بالحق (ولكن ليس
            الالتزام) في إزالة أو تعديل أي محتوى من هذا القبيل. يرجى عدم إرسال
            أي مواد لا تنوي إخضاعها لترخيص المحتوى المقدم من المستخدم والموضح في
            هذه الفقرة. يُشار إلى كل المحتوى الموصوف في الفقرة السابقة مباشرة
            وأي وجميع المعلومات أو المحتوى أو المواد الأخرى التي تنشرها أو
            ترسلها إلينا فيما بعد بشكل جماعي باسم "المحتوى من إنشاء المستخدم".
            إذا قمت بنشر أو إرسال أي محتوى عن قصد أو عن غير قصد فإنه سيكون لدينا
            حقوق غير مقيدة لاستخدامه لأي من الأغراض مهما كانت، سواء كانت تجارية
            أو غير ذلك، دون أي إذن إضافي منك أو أي مدفوعات لك أو لأي شخص آخر.
            يحق لنا وللمفوضين لدينا أيضًا استخدام الاسم الذي ترسله، بالإضافة إلى
            أي اسم آخر تعرفه أو قد تكون معروفًا به فيما يتعلق بالمحتوى. بدون
            الحد من عمومية ما سبق، فإنك تمنحنا بموجب هذا دون قيد أو شرط ترخيصًا
            شاملاً ودائمًا وغير حصري وغير قابل للإلغاء وغير مدفوع بالكامل وخاليا
            من حقوق الملكية وقابلا للترخيص وقابلا للتحويل لاستخدام وإعادة
            استخدام وإعادة إنتاج ونقل وطباعة ، نشر ، عرض ، عرض ، توزيع ، إعادة
            توزيع ، نسخ ، استضافة ، تخزين ، أرشفة ، تصنيف ، تعليق ، بث ، بث ،
            تنزيل ، تحرير ، تعديل ، تعديل ، تكييف ، ترجمة ، إنشاء أعمال مشتقة
            على أساس بناءً على المحتوى الذي تنشره أنت كمستخدم، كليًا أو جزئيًا ،
            بجميع الوسائل وفي جميع الوسائط المعروفة الآن أو التي تم ابتكارها لأي
            غرض من الأغراض دون إشعار آخر لك وبإسناد أو بدون إسناد. أنت توافق على
            ترخيص المحتوى سواء استخدمنا المحتوى الذي أنشأته أم لا
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            المحتوى المرتبط بالموقع
          </h1>
          <p className="font-medium leading-loose break-words">
            يجب أن تدرك أنه عند زيارتك للموقع ، قد يتم توجيهك إلى مواقع أخرى
            خارجة عن موقعنا بما في ذلك الروابط إلى أو من الشركات التابعة أو
            المواقع التي نخزن فيها محتويات موقعنا. عندما تنقر على رابط يوجهك
            خارج موقعنا، قد لا نتحكم في الموقع الذي يتم توجيهك إليه وقد يتم
            تطبيق شروط الاستخدام وسياسات الخصوصية المختلفة التي يجب عليك قراءتها
            بعناية. أنت تقر بأننا لسنا مسؤولين عن مراجعة وتقييم شروط أي موقع آخر
            خارج موقعنا بالرغم من استعمالنا له، وأننا لا نضمن عروض أي طرف ثالث
            أو محتوى مواقعهم. نحن لا نتحمل أي مسؤولية أو مسؤولية عن الإجراءات أو
            المنتجات أو المحتوى من أي طرف ثالث أو أي موقع طرف ثالث. نحتفظ بالحق
            في تعطيل الروابط من أو إلى مواقع الطرف الثالث في أي وقت
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            إخلاء المسؤولية عن الضمانات
          </h1>
          <p className="font-medium leading-loose break-words">
            يتم توفير المحتوى الموجود على الموقع "كما هو" وبدون أي ضمان من أي
            نوع ، صريحًا أو ضمنيًا. إلى أقصى حد يسمح به القانون المعمول به ،
            فإننا نخلي مسؤوليتنا عن جميع الضمانات ، سواء كانت صريحة أو ضمنية،
            بما في ذلك، على سبيل المثال لا الحصر، الضمانات الضمنية لقابلية
            التسويق والصلاحية لغرض معين. نحن لا نضمن المواد لن يتم قطعها أو
            خلوها من الأخطاء، أو أنه سيتم تصحيح العيوب، كما لا نضمن أن موقعنا
            الإلكتروني أو الخوادم التي نستخدمها ستكون دائما خالية من الفيروسات
            أو المكونات الضارة الأخرى. لذلك فنحن لا نتحمل تكاليف إصلاح أو تصحيح
            أي من معداتك أو برامجك في حال تعرضها للضرر. نحن لا نقدم أي إقرارات
            أو ضمانات فيما يتعلق بالاستخدام، أو نتائج الاستخدام، لأي محتوى أو
            منتج أو خدمة معروضة على موقعنا (ولا يوجد على وجه التحديد أي تمثيل أو
            ضمان من الدقة أو الاكتمال أو الموثوقية أو الأمان). نحن نتنازل بشكل
            واضح عن أي مسؤولية عن دقة أو اكتمال أو توفر المعلومات والمحتوى
            والمواد الموجودة في المواقع التي ترتبط بموقع ميرفيت ستريمس للتدريب .
            لا يمكننا التأكد من أنك سوف تكون راضياً عن أي منتج أو خدمة أو محتوى
            تشتريه من موقعنا. نحن نشجعك بشدة على إجراء أي تحقيق تشعر به ضروريًا
            أو مناسبًا قبل إجراء أي معاملة شراء عبر الإنترنت أو دون اتصال مع أي
            طرف ثالث. الموقع الإلكتروني والمنتجات / الخدمات الواردة فيه ليست
            بدائل لتقديم المشورة والعلاج النفسي والرعاية الصحية المرخصة. ليست كل
            المنتجات والخدمات مناسبة للجميع. نحن لا نقدم أي إقرارات أو ضمانات
            ونخلي مسؤوليتها صراحةً عن أي وجميع المسؤوليات المتعلقة بأي علاج أو
            أي إجراء يتبع المعلومات المقدمة أو المقدمة داخل الموقع أو من خلاله.
            إذا كان لديك مخاوف معينة أو حالة تتطلب منك المشورة المهنية أو الطبية
            ، فينبغي استشارة طبيب متخصص ومؤهل بشكل مناسب ، مثل طبيب نفساني مرخص
            أو طبيب متخصص في الصحة. لا تتجاهل أبدًا النصيحة الطبية لأخصائي علم
            النفس أو الطبيب أو غيره من المتخصصين في مجال الصحة بسبب المعلومات
            التي يتم تقديمها أو توفيرها داخل الموقع أو من خلاله. أنت تقر بأنك قد
            قرأت بعناية "هذا التنازل" وأنك تفهم تمامًا أن هذا هو سبب المسؤولية.
            أنت توافق صراحةً على التنازل عن جميع الدعاوى أو أسباب الدعوى ،
            وتوافق على الاستسلام والتنازل نهائيًا عن التنازل عن أية حقوق قد
            تلزمها في أي وقت الطرف المعوض عن الإصابة الشخصية أو أضرار الممتلكات
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            شكاوى حقوق الطبع والنشر
          </h1>
          <p className="font-medium leading-loose break-words">
            نحن نحترم حقوق الملكية الفكرية للآخرين. إذا كنت تعتقد أن عملك قد تم
            نسخه على موقعنا بطريقة تشكل انتهاكًا لحقوق الطبع والنشر، فيرجى
            التواصل معنا حتى نراجع الموضوع
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            التعديل
          </h1>
          <p className="font-medium leading-loose break-words">
            نحن نحتفظ بالحق ، وفقًا لتقديرنا الخاص ، في تغيير أو تعديل أو إضافة
            أو حذف أجزاء من شروط الاستخدام هذه في أي وقت دون إشعار ، وتقع على
            عاتقك مسؤولية مراجعة شروط الاستخدام هذه لأي تغييرات. إن استخدامك
            للموقع بعد أي تغيير في شروط الاستخدام هذه سيشكل موافقتك على شروط
            الاستخدام المنقحة وقبولها
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            الإنهاء
          </h1>
          <p className="font-medium leading-loose break-words">
            تسري شروط الاستخدام هذه حتى يتم إنهاؤها بواسطتك أو بواسطتنا. يجوز لك
            إنهاء شروط الاستخدام هذه في المستقبل في أي وقت من خلال إيقاف وصولك
            إلى الموقع واستخدامه وحذف جميع المواد التي قمت الحصول عليها من
            الموقع وجميع الوثائق ذات الصلة وجميع النسخ والتثبيتات الخاصة بنا،
            سواء تم إجراؤها بموجب شروط الاستخدام هذه أو خلاف ذلك. إذا قمت بإنهاء
            شروط الاستخدام هذه ، يجب عليك إخطارنا عن طريق إرسال إشعار بهذا
            الإنهاء عن طريق بريدنا الإلكتروني
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            القانون المطبق
          </h1>
          <p className="font-medium leading-loose break-words">
            تخضع شروط الاستخدام هذه وحقوقك والتزاماتك وحقوقنا والتزاماتنا وجميع
            الإجراءات المنصوص عليها في شروط الاستخدام هذه إلى القوانين الجاري
            بها العمل، كما لو كانت شروط الاستخدام هذه عبارة عن عقد تم إبرامه
            بالكامل وتم تنفيذه بالكامل
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            الإتصالات الإلكترونية
          </h1>
          <p className="font-medium leading-loose break-words">
            عندما تقوم بزيارة موقعنا ميرفيت ستريمس للتدريب ، أو إرسال رسائل بريد
            إلكتروني إلينا، أو التواصل مع أرقامنا، فأنت تتواصل معنا إلكترونيًا.
            أنت توافق على تلقي الاتصالات منا إلكترونيًا. سوف نتواصل معك عن طريق
            البريد الإلكتروني أو عن طريق نشر الإشعارات على الموقع. أنت توافق على
            استلامك رسائل من موقعنا سواء على بريدك الإلكتروني أو على رقمك
            الهاتفي أو على الواتساب الخاص بك
          </p>
          <h1 className="font-extrabold leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            أحكام قانونية متنوعة
          </h1>
          <p className="font-medium leading-loose break-words">
            يجوز لنا إيقاف الموقع في أي وقت ولأي سبب دون سابق إنذار. يجوز لنا
            تغيير محتويات أو تشغيل أو ميزات الموقع في أي وقت ولأي سبب دون إشعار.
            أنت توافق على عدم وجود علاقة مشتركة أو شراكة أو توظيف أو علاقة وكالة
            بينك وبيننا نتيجة لشروط الاستخدام هذه أو استخدامك للموقع. لا يوجد أي
            شيء وارد في شروط الاستخدام هذه ينتقص من حقنا في الامتثال لطلبات أو
            متطلبات الحكومة أو المحكمة أو إنفاذ القانون المتعلق باستخدامك للموقع
            أو المعلومات المقدمة لنا أو التي جمعناها فيما يتعلق بهذا الاستخدام.
            يجوز قبول نسخة مطبوعة من شروط الاستخدام هذه وأي إشعار يتم تقديمه في
            شكل إلكتروني في الإجراءات القضائية أو الإدارية بناءً على شروط
            الاستخدام هذه أو تتعلق بها بنفس القدر والخاضعة لنفس الشروط مثل وثائق
            وسجلات الأعمال الأخرى في الأصل. لا يشكل فشلنا في تنفيذ أي حكم من
            أحكام شروط الاستخدام هذه أو الرد على خرق من جانبك أو من قبل الآخرين
            تنازلاً عن حقنا في فرض أي حكم آخر من شروط الاستخدام هذه فيما يتعلق
            بهذا الخرق أو أي خرق آخر إذا كان أي شرط من شروط الاستخدام هذه غير
            صالح أو غير قابل للتنفيذ بموجب القانون المعمول به ، فستستمر الأحكام
            المتبقية في التنفيذ والتأثير الكامل ، وسيتم اعتبار الحكم غير صالح أو
            غير القابل للتنفيذ محكومًا به حكما صالحا وقابلا للتنفيذ يتطابق بشكل
            وثيق مع القصد من الحكم الأصلي. تشكل شروط الاستخدام هذه الاتفاقية
            الكاملة بينك وبيننا فيما يتعلق بالموقع الإلكتروني وتحل محل أي
            اتفاقية سابقة أو حالية تتعلق بهذا الموضوع
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicyPage;
