import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { storeContext } from "./../../global/store";
import jwt from "jwt-decode";
import { login, storeTheUser, loginWithGoogle } from "./../../services/auth";
import { GoogleLogin } from "react-google-login";

import { IconContext } from "react-icons";
import { FcCdLogo, FcGoogle } from "react-icons/fc";

import { toast } from "react-toastify";

import { useLastLocation } from "react-router-last-location";

import "./login.css";

const Login = () => {
  const { store, setStore } = useContext(storeContext);
  let history = useHistory();

  const lastLocation = useLastLocation();

  // alert(`${lastLocation.pathname}`);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
  });

  if (store.isLogged) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="flex flex-row h-screen login-page">
      <div className="hidden md:block bg-pink-200 h-screen w-1/2 overview-sl"></div>
      <div className="h-screen overflow-y-auto x-sm:w-full md:w-1/2 flex flex-col x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right text-reghda-black x-sm:px-8 md:px-12 2xl:px-24 x-sm:text-sm md:text-base 2xl:text-3xl">
        <Link to="/">
          <div className="p-4 flex flex-col items-center">
            <img
              className="flex-1 x-sm:w-32 x-sm:h-32 md:w-20 md:h-20 2xl:w-48 2xl:h-48"
              alt="logo"
              src="/images/mervet_logo.png"
            />
          </div>
        </Link>
        <p className="md:text-2xl 2xl:text-6xl font-extrabold">تسجيل الدخول</p>
        <p className="md:text-xl 2xl:text-5xl font-medium md:leading-relaxed 2xl:leading-relaxed">
          للقيام بتسجيل الدخول, يرجى إدخال البريد الألكتروني و كلمة السر الخاصين
          بك
        </p>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const res = await login(values);

            if (res && res.status === 200 && res.data) {
              const token = res.data.access;
              const refresh = res.data.refresh;
              const user = jwt(res.data.access);
              setStore({
                ...store,
                token,
                user,
                isLogged: true,
              });
              storeTheUser(user, token, refresh);
              if (lastLocation) {
                // history.push(lastLocation.pathname);
                window.location = lastLocation.pathname;
              } else {
                // history.push("/");
                window.location = "/";
              }
              // window.history.back();
              // history.goBack();
              // navigate(-1)
            }

            if (
              res &&
              res.data.message ===
                "هذا الحساب غير مفعل ، يرجى التحقق من رسائل البريد الإلكتروني الخاصة بك لتفعيل حسابك."
            ) {
              history.push("/user_reactivation");
            }
            if (
              res &&
              res.status === 400 &&
              res.data.message !==
                "هذا الحساب غير مفعل ، يرجى التحقق من رسائل البريد الإلكتروني الخاصة بك لتفعيل حسابك."
            ) {
              toast(res.data.message);
            }

            setSubmitting(false);
          }}
        >
          <Form className="text-white x-sm:space-y-2 md:space-y-4 2xl:space-y-6">
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="البريد الالكتروني"
              name="email"
              type="email"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="email" />
            </div>
            <Field
              className="bg-pink-200 w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="كلمة السر"
              name="password"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="password" />
            </div>
            <button
              className="bg-reghda-black text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
              type="submit"
            >
              تسجيل الدخول
            </button>

            <GoogleLogin
              clientId="779569317705-egb7etmkgld1ktket9rffhcfku20gjlf.apps.googleusercontent.com"
              render={(renderProps) => (
                <div
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                  className="cursor-pointer flex flex-row items-center justify-center space-x-2 bg-white text-pink-300 text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-2xl"
                >
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#fff" } }}
                  >
                    <FcGoogle></FcGoogle>
                  </IconContext.Provider>
                  <p>دخول بغوغل</p>
                </div>
              )}
              buttonText="سجل بغوغل"
              onSuccess={async (res) => {
                let token = res.tokenId;
                let user = { ...jwt(token) };

                let values = {
                  username: user.name,
                  email: user.email,
                  auth_provider: "google.com",
                  family_name: user.family_name,
                  given_name: user.given_name,
                  image: user.picture,
                };

                const res2 = await loginWithGoogle(values);

                if (res2 && res2.status === 200 && res2.data) {
                  const token2 = res2.data.access;
                  const refresh2 = res2.data.refresh;
                  const user2 = jwt(res2.data.access);
                  setStore({
                    ...store,
                    token2,
                    user2,
                    isLogged: true,
                  });
                  storeTheUser(user2, token2, refresh2);
                  if (lastLocation) {
                    // history.push(lastLocation.pathname);
                    window.location = lastLocation.pathname;
                  } else {
                    // history.push("/");
                    window.location = "/";
                  }
                  // window.history.back();
                  // history.goBack();
                  // navigate(-1)
                } else {
                  toast("something wrong!");
                }
              }}
              onFailure={(res) => {
                // alert("something wrong!, please try again");
              }}
              cookiePolicy={"single_host_origin"}
            />
          </Form>
        </Formik>
        <Link to="/reset_password">
          <span className="text-pink-200"> نسيت كلمة المرور ؟ </span>
        </Link>
        <Link to="/signup">
          ليس لديك حساب ؟ <span className="text-pink-200">سجل معنا</span>
        </Link>
      </div>
    </div>
  );
};

export default Login;

//779569317705-egb7etmkgld1ktket9rffhcfku20gjlf.apps.googleusercontent.com

//779569317705-gpjcu6dvptj6r66e25e4udcro16umd03.apps.googleusercontent.com
