import React, { useState, useEffect } from "react";
import "./FollowMe.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Content from "./../../components/Content/Content";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import { Link } from "react-router-dom";

import Slider from "react-slick";

function FollowMe() {
  const getPosts =
    "https://graph.instagram.com/me/media?fields=id,username,timestamp,caption,media_url,media_type,permalink&access_token=IGQVJWeDFxZA2RGdTB3c2tuZAXNxNk02SzU5aTVRVVFVTllIal90ekZAnUkJSQzNwLTJ5bzBLbjFTbXlRZAmktR0E1VDluenpNWkhacEZAHWnptcHpfUWduRGdfVWxKcGNOdGJmSlk3dTFR";

  const { data, error } = useSWR(getPosts, fetcher);

  useEffect(() => {
    if (data) {
      const onlyImages = data.data.filter(
        (item) => item.media_type === "IMAGE"
      );
      const imagesList = onlyImages.map((item) => item.media_url);
      console.log(imagesList, "🎉");
      setinstaPics(imagesList.filter((e, i) => i < 10));
    }
  }, [data]);

  const [instaPics, setinstaPics] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 7000,
    autoplaySpeed: 7000,
    // speed: 3500,
    // autoplaySpeed: 3500,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section>
      <Slider {...settings}>
        {instaPics.map((e, i) => (
          <img src={e} alt="instagram"></img>
        ))}
      </Slider>
    </section>
  );
}

export default FollowMe;
