import React, { useState, useEffect } from "react";
import Title from "../../components/Title/Title";
import "./CourseContent1.css";
import Content from "../../components/Content/Content";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ChapterItems from "./ChapterItems";
import VideoPlayer from "./VideoPlayer";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import Reviews from "./Reviews";

function CourseContent1() {
  const { slug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/courses/published/all/${slug}`,
    fetcher
  );
  data && console.log(data);
  const [totalState, SetTotalState] = useState({
    chapters: [],
    // numberOfChapters: 3,
  });

  const [currentUrl, setCurrentUrl] = useState("");
  const [currentAttachment, setCurrentAttachment] = useState({
    files: [],
    images: [],
    audios: [],
  });
  const [currentType, setCurrentType] = useState("video");

  const [currentChapter, setCurrentChapter] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);

  useEffect(() => {
    data &&
      SetTotalState({
        chapters: data.chapters.map((chapter, i) => {
          return { ...chapter, numberOfModules: chapter.modules.length };
        }),
        numberOfChapters: data.chapters.length,
      });
  }, [data]);

  const setupCurrent = (chapterId, moduleId) => {
    if (chapterId && moduleId) {
      let thisChapter = totalState.chapters.filter(
        (chapter) => chapter.id === chapterId
      )[0];
      let thisModule = thisChapter.modules.filter(
        (module) => module.id === moduleId
      )[0];

      if (thisModule.videos.length > 0) {
        thisModule.videos[0].url === ""
          ? setCurrentUrl(thisModule.videos[0].video_content)
          : setCurrentUrl(thisModule.videos[0].url);
      }

      setCurrentAttachment({
        files: [...thisModule.files],
        images: [...thisModule.images],
        audios: [...thisModule.audios],
      });
    }
  };

  const next = () => {
    let nextModuleIdx;
    let nextChapterIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        nextChapterIdx = i + 1;
        return chapter;
      }
    })[0];

    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        nextModuleIdx = i + 1;
        return module;
      }
    })[0];

    if (nextModuleIdx < thisChapter.numberOfModules) {
      let nextModule = thisChapter.modules.filter(
        (module, i) => i === nextModuleIdx
      )[0];
      setupCurrent(thisChapter.id, nextModule.id);
      setCurrentChapter(thisChapter.id);
      setCurrentModule(nextModule.id);
    } else {
      if (nextChapterIdx < totalState.numberOfChapters) {
        let nextChapter = totalState.chapters.filter(
          (chapter, i) => i === nextChapterIdx
        )[0];
        let firstModule = nextChapter.modules.filter((module, i) => i === 0)[0];
        setupCurrent(nextChapter.id, firstModule.id);
        setCurrentChapter(nextChapter.id);
        setCurrentModule(firstModule.id);
      }
    }
  };

  const previous = () => {
    let prevModuleIdx;
    let prevChapterIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        prevChapterIdx = i - 1;
        return chapter;
      }
    })[0];

    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        prevModuleIdx = i - 1;
        return module;
      }
    })[0];

    if (prevModuleIdx >= 0) {
      let prevModule = thisChapter.modules.filter(
        (module, i) => i === prevModuleIdx
      )[0];
      setupCurrent(thisChapter.id, prevModule.id);
      setCurrentChapter(thisChapter.id);
      setCurrentModule(prevModule.id);
    } else {
      if (prevChapterIdx >= 0) {
        let prevChapter = totalState.chapters.filter(
          (chapter, i) => i === prevChapterIdx
        )[0];
        let lastModule = prevChapter.modules.filter(
          (module, i) => i === prevChapter.numberOfModules - 1
        )[0];
        setupCurrent(prevChapter.id, lastModule.id);
        setCurrentChapter(prevChapter.id);
        setCurrentModule(lastModule.id);
      }
    }
  };

  const showNext = () => {
    let currentChapterIdx;
    let currentModuleIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        currentChapterIdx = i;
        return chapter;
      }
    })[0];
    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        currentModuleIdx = i;
        return module;
      }
    })[0];

    if (
      currentChapterIdx === totalState.numberOfChapters - 1 &&
      currentModuleIdx === thisChapter.numberOfModules - 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showPrev = () => {
    let currentChapterIdx;
    let currentModuleIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        currentChapterIdx = i;
        return chapter;
      }
    })[0];
    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        currentModuleIdx = i;
        return module;
      }
    })[0];

    if (currentChapterIdx === 0 && currentModuleIdx === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="course__content__1">
      <div className="course__content__1__container">
        {!currentModule && (
          <div className="course__content__leftside">
            <div className="w-full flex flex-col space-y-4 text-center items-center justify-center font-extrabold md:text-lg lg:text-2xl 2xl:text-4xl">
              <Title content="إبدا الدورة الأن بإختيار درس" />
              <img src="/images/play.svg" alt="play" className="play"></img>
            </div>
          </div>
        )}
        {currentModule && (
          <div className="course__content__leftside">
            <div className="course__item w-full">
              <div className="course__section__title">
                <img src="/images/video-solid.svg" alt="video"></img>
                <Title content="الفيديو" />
              </div>
              <div className="course__video w-full">
                <VideoPlayer
                  next={next}
                  type={currentType}
                  url={currentUrl}></VideoPlayer>
              </div>
            </div>
            {/* <div className="course__item">
            <div className="course__section__title">
              <img src={volumeIcon}></img>
              <Title content="تسجيلات صوتية" />
            </div>
            <div className="course__memos">
              <div className="course__memos__item">
                <Content content="تسجيل صوتي رقم 1" />
                <div className="memo">
                  <i class="fa fa-play-circle"></i>
                  <div className="memo__progress"></div>
                </div>
              </div>

              <div className="course__memos__item">
                <Content content="تسجيل صوتي رقم 2" />
                <div className="memo">
                  <i class="fa fa-play-circle"></i>
                  <div className="memo__progress"></div>
                </div>
              </div>
            </div>
          </div> */}
            <div className="course__item">
              {currentAttachment &&
                (currentAttachment.files.length > 0 ||
                  currentAttachment.images.length > 0 ||
                  currentAttachment.audios.length > 0) && (
                  <div className="course__section__title">
                    <img src="/images/file-pdf-solid.svg" alt="pdf"></img>
                    <Title content="مرفقات" />
                  </div>
                )}
              <div className="course__attachments">
                {currentAttachment.files.length > 0 &&
                  currentAttachment.files.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.file_content)
                      : (where = item.url);
                    return (
                      <div className="course__attachment__item">
                        <a
                          href={where}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="course__attachment__download">
                          <i class="fa fa-download"></i>
                          <Content content="تحميل" />
                        </a>
                        <Content content={item.title} />
                      </div>
                    );
                  })}
                {currentAttachment.images.length > 0 &&
                  currentAttachment.images.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.image_content)
                      : (where = item.url);
                    return (
                      <div className="course__attachment__item">
                        <a
                          href={where}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="course__attachment__download">
                          <i class="fa fa-download"></i>
                          <Content content="تحميل" />
                        </a>
                        <Content content={item.title} />
                      </div>
                    );
                  })}
                {currentAttachment.audios.length > 0 &&
                  currentAttachment.audios.map((item, i) => {
                    let where;
                    item.url === ""
                      ? (where = item.audio_content)
                      : (where = item.url);
                    return (
                      <div className="course__attachment__item">
                        <a
                          href={where}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="course__attachment__download">
                          <i class="fa fa-download"></i>
                          <Content content="تحميل" />
                        </a>
                        <Content content={item.title} />
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="w-full">
              {showPrev() && (
                <button
                  className="text-white bg-mervet-pink x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-left"
                  onClick={previous}>
                  السابق
                </button>
              )}
              {showNext() && (
                <button
                  className="text-white bg-mervet-pink x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-right"
                  onClick={next}>
                  التالي
                </button>
              )}
            </div>
            <Reviews
              setLoading={setLoading}
              courseId={data.id}
              moduleId={currentModule}
            />
          </div>
        )}
        <div className="course__content__rightside">
          <div className="course__program__container">
            <ChapterItems
              setCurrentChapter={setCurrentChapter}
              currentChapter={currentChapter}
              setCurrentModule={setCurrentModule}
              currentModule={currentModule}
              setupCurrent={setupCurrent}
              chapters={totalState.chapters}></ChapterItems>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseContent1;
