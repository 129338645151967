import React, { useState } from "react";
import Modal from "react-modal";
import { unlockChapter } from "../../services/followCourses";
import LoaderModal from "./../../components/LoaderModal";
import { getTheUserFromStorage } from "./../../services/auth";
import { mutate } from "swr";

const OpenNextForGroup = ({ modalIsOpen, closing, courseId }) => {
  const [loading, setLoading] = useState(false);
  const [ask, setAsk] = useState(true);
  const [msg, setMsg] = useState(null);

  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          borderColor: "#fff",
          width: "50%",
        },
      }}>
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue">
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      {ask && (
        <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
          <h3 className="text-reghda-black font-semibold">
            هل حضرة جلسة إستشارة لهذا الفصل ؟
          </h3>
          <div className="w-1/2 flex flex-row items-center justify-center space-x-3">
            <button
              onClick={async () => {
                setLoading(true);
                const res = await unlockChapter({
                  user: getTheUserFromStorage().user_id,
                  course: courseId,
                });
                if (res && res.status === 200) {
                  setMsg(res.data.message);
                }
                setLoading(false);
                setAsk(false);
                mutate(
                  `https://mervatsreams.smartiniaclient.com/subscriptions/follow-up/details/${
                    getTheUserFromStorage().user_id
                  }/${courseId}`
                );
                setTimeout(() => {
                  closing();
                }, 5000);
              }}
              className="x-sm:w-full md:w-1/2 bg-green-300 text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl">
              نعم
            </button>
            <button
              onClick={async () => {
                setMsg(
                  "يجب عليك حضور جلسة إستشارة لهذا الفصل حتى يفتح لك الفصل التالي"
                );
                setAsk(false);
                setTimeout(() => {
                  closing();
                }, 5000);
              }}
              className="x-sm:w-full md:w-1/2 bg-red-400 text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl">
              لا
            </button>
          </div>
        </div>
      )}
      {!ask && (
        <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
          <h3 className="text-reghda-black font-semibold">{msg}</h3>
        </div>
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default OpenNextForGroup;
