import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Title from "../../components/Title/Title";
import Content from "../../components/Content/Content";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import axios from "axios";
import { getTheUserFromStorage } from "./../../services/auth";
import { storeContext } from "./../../global/store";
import LoaderModal from "./../../components/LoaderModal";
import { addToCart } from "../../services/cart";
import VideoPlayer from "./VideoPlayer";
import Reviews from "./Reviews";
import parse from "html-react-parser";
import "./CourseOverview.css";

function CourseOverview() {
  const { store, setStore } = useContext(storeContext);
  const { type, slug } = useParams();
  const history = useHistory();

  const { data, error } = useSWR(
    `https://mervatsreams.smartiniaclient.com/courses/published/paid/${slug}`,
    fetcher
  );

  const [loading, setLoading] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState([]);

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        `https://mervatsreams.smartiniaclient.com/students/mycourses/${data.id}`,
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setAlreadyPaid(res.data);
      return res;
    }
    data && store.isLogged && fetch();
  }, [data]);

  const courseTitleClick = (e, courseItem) => {
    // e.target.classList.toggle("active__course__title")

    var courseTitle = document.getElementById("coursetitle_" + courseItem.id);
    courseTitle.classList.toggle("active__course__title");
  };

  return (
    <div className="course__overview">
      <section className="course__title__section">
        {data && <img src={data.picture} alt="courseImg"></img>}
        <div className="course__title__overlay" />
        <div>
          {data && <Title color="white" font="big" content={data.title} />}
        </div>
      </section>
      <section className="course__overview__section">
        <div className="course__leftside">
          <div className="course__video">
            {data && data.course_video && (
              <VideoPlayer url={data.course_video} type="video" />
            )}
            {/* <img src="/images/play.svg" alt="play" className="play"></img> */}
          </div>
          <div className="course__items">
            <ul>
              {data && (
                <li>
                  <div>
                    <img src="/images/video.svg" alt="video"></img>
                    <Content content="فيديو" />
                  </div>
                  <Content content={data?.nb_videos} />
                </li>
              )}
              {data && (
                <li>
                  <div>
                    <img src="/images/pdf.svg" alt="pdf"></img>
                    <Content content="مرفقات" />
                  </div>
                  <Content content={data?.nb_files} />
                </li>
              )}
              {data && (
                <li>
                  <div>
                    <img src="/images/time.svg" alt="time"></img>
                    <Content content="المدة" />
                  </div>
                  <Content content={`${data?.duration} اسابيع`} />
                </li>
              )}

              {(type === "paid" || type === "follow-up") &&
                data &&
                alreadyPaid &&
                alreadyPaid.length === 0 && (
                  <li>
                    <div>
                      <Content content="السعر" />
                    </div>
                    <Content
                      style={
                        data?.discounted_price
                          ? {
                              textDecoration: "line-through",
                              color: "#D1D5DB",
                            }
                          : {}
                      }
                      content={`${data?.price} دولار`}
                    />
                    {data?.discounted_price && (
                      <Content content={`${data?.discounted_price} دولار`} />
                    )}
                  </li>
                )}
            </ul>

            {(type === "paid" || type === "follow-up") && !store.isLogged && (
              <Link to="/login" className="course__subscribe__btn">
                <Title content="سجل للشراء" color="white" />
              </Link>
            )}

            {(type === "paid" || type === "follow-up") &&
              store.isLogged &&
              alreadyPaid &&
              alreadyPaid.length === 0 && (
                <button
                  onClick={async () => {
                    // add to cart
                    setLoading(true);
                    let res = await addToCart({
                      course: data.id,
                      user: getTheUserFromStorage().user_id,
                    });
                    if (res.status === 200) {
                      setLoading(false);
                      history.push("/cart");
                    } else {
                      setLoading(false);
                      alert("something wrong,retry");
                    }
                  }}
                  className="course__subscribe__btn">
                  <Title content="أضف الى السلة" color="white" />
                </button>
              )}
            {(type === "paid" || type === "follow-up") &&
              store.isLogged &&
              alreadyPaid &&
              alreadyPaid.length > 0 && (
                <Link
                  to={`/course-content/${data.slug}`}
                  className="course__subscribe__btn">
                  <Title content="اذهب الى الدورة" color="white" />
                </Link>
              )}

            {type === "free" && (
              <button
                onClick={() => {
                  history.push(`/course-content/${slug}`, {
                    data,
                  });
                }}
                className="course__subscribe__btn">
                <Title content="شاهد الدورة" color="white" />
              </button>
            )}
          </div>
        </div>
        <div className="course__rightside">
          {((data && !store.isLogged) ||
            (data && alreadyPaid && alreadyPaid.length === 0)) && (
            <>
              <div className="course__overview__item">
                <Title content=": حول البرنامج" font="big" />
                <Content
                  content={
                    data?.about_course ? parse(data?.about_course) : null
                  }
                />
              </div>
              <div className="course__overview__item">
                <Title content=": وصف الدورة" font="big" />
                <Content
                  content={data?.description ? parse(data?.description) : null}
                />
              </div>
              <div className="course__overview__item">
                <Title content=": على ماذا سوف تحصل" font="big" />
                <Content
                  content={
                    data?.what_you_get ? parse(data?.what_you_get) : null
                  }
                />
              </div>
            </>
          )}
          {data && store.isLogged && alreadyPaid && alreadyPaid.length > 0 && (
            <div className="course__overview__item">
              <Title content=": معلومات حول الدورة" font="big" />
              {/* {data ? console.log(data) : null} test */}
              <Content
                content={
                  data?.course_information
                    ? parse(data?.course_information)
                    : null
                }
              />
            </div>
          )}
          {data && data.chapters.length > 0 && (
            <div className="course__overview__item">
              <Title content=": البرنامج" font="big" />
              <div className="course__program__container">
                {data &&
                  data.chapters &&
                  data.chapters.map((chapter) => {
                    return (
                      <div
                        key={chapter.id}
                        id={"coursetitle_" + chapter.id}
                        className="course__title"
                        onClick={(e) => courseTitleClick(e, chapter)}>
                        <div className="course__title__container">
                          <i class="fa fa-arrow-circle-left"></i>
                          <Title content={chapter.title} color="white" />
                        </div>
                        <div className="course__items__container">
                          {chapter.modules &&
                            chapter.modules.map((module) => {
                              return (
                                <div
                                  key={module.id}
                                  className="course__subtitle">
                                  <Content content={module.title} />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {data && (
            <Reviews
              setLoading={setLoading}
              isLogged={store.isLogged}
              alreadyPaid={alreadyPaid}
              courseId={data.id}
            />
          )}
        </div>
      </section>
      <LoaderModal modalIsOpen={loading || !data}></LoaderModal>
    </div>
  );
}

export default CourseOverview;
