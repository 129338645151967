import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { storeContext } from "../global/store";
import ProtectedRoute from "./ProtectedRoute";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HomePage from "../pages/HomePage/HomePage";
import About from "../pages/About/About";
import Blog from "../pages/Blog/Blog";
import CoachingOneOne from "../pages/Coaching_1_1/Coaching_1_1";
import OneOneCoachingDetail from "../pages/OneOneCoachingDetail/OneOneCoachingDetail";
import GroupCoaching from "../pages/GroupCoaching/GroupCoaching";
import GroupCoachingCourses from "../pages/group-coaching-courses/Page";
import Tutorials from "../pages/Tutorials/Tutorials";
import CourseOverview from "../pages/CourseOverview/CourseOverview";
import Contact from "../pages/Contact/Contact";
import CourseContent1 from "../pages/CourseContent1/CourseContent1";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Cart from "../pages/cart/Cart";
import CheckOut from "../pages/check-out/CheckOut";
import MyCourses from "../pages/my-courses/Page";
import MyOrders from "../pages/my-orders/Page";
import MyConsultations from "../pages/my-consultations/Page";
import Consultations from "../pages/consultations/Page";
import UserActivation from "./../pages/UserActivation/UserActivation";
import UserReactivation from "./../pages/UserActivation/UserReactivation";
import ResetPassword from "./../pages/ResetPassword/Page";
import Schedule from "./../pages/schedule/Page";
import CheckOutConsulting from "./../pages/check-out-consultation/Page";
import Settings from "./../pages/settings/Page";
import FollowCourseContent from "./../pages/FollowCourseContent/FollowCourseContent";
import Articles from "./../pages/articles/Articles";
import ArticleContent from "./../pages/article-content/ArticleContent";
import PrivacyPolicyPage from "./../pages/policy-pages/PrivacyPolicy";

import ScrollToTop from "./scrollToTop";
import { LastLocationProvider } from "react-router-last-location";

function App() {
  const { store } = useContext(storeContext);

  return (
    <div className="App">
      <Router>
        <LastLocationProvider>
          <ScrollToTop />

          {/* <Header /> */}
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props}></Login>}
            />
            <Route
              exact
              path="/signup"
              render={(props) => <Signup {...props}></Signup>}
            />
            <Route
              exact
              path="/"
              render={(props) => (
                <>
                  <Header />
                  <HomePage {...props}></HomePage>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/about"
              render={(props) => (
                <>
                  <Header />
                  <About {...props}></About>
                  <Footer />
                </>
              )}
            />

            <Route
              path="/group-coaching-courses"
              exact
              render={(props) => (
                <GroupCoachingCourses {...props}></GroupCoachingCourses>
              )}
            ></Route>

            <Route
              path="/user_activation"
              exact
              render={(props) => <UserActivation {...props}></UserActivation>}
            ></Route>
            <Route
              path="/user_reactivation"
              exact
              render={(props) => (
                <UserReactivation {...props}></UserReactivation>
              )}
            ></Route>
            <Route
              path="/reset_password/:userId/:token"
              exact
              render={(props) => <ResetPassword {...props}></ResetPassword>}
            ></Route>
            <Route
              path="/reset_password"
              exact
              render={(props) => <ResetPassword {...props}></ResetPassword>}
            ></Route>
            <Route
              exact
              path="/blog"
              render={(props) => (
                <>
                  <Header />
                  <Blog {...props}></Blog>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/articles"
              render={(props) => (
                <>
                  <Header />
                  <Articles {...props}></Articles>
                  <Footer />
                </>
              )}
            />
            <ProtectedRoute
              isLogged={store.isLogged}
              exact
              path="/articles/:slug"
              render={(props) => (
                <>
                  <Header />
                  <ArticleContent {...props}></ArticleContent>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/1-1_coaching"
              render={(props) => (
                <>
                  <Header />
                  <CoachingOneOne {...props}></CoachingOneOne>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/group-coaching/:slug"
              render={(props) => (
                <>
                  <Header />
                  <GroupCoaching {...props}></GroupCoaching>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/one-one-coaching/:slug"
              render={(props) => (
                <>
                  <Header />
                  <OneOneCoachingDetail {...props}></OneOneCoachingDetail>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/contact"
              render={(props) => (
                <>
                  <Header />
                  <Contact {...props}></Contact>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/tutorials/:courseCategory"
              render={(props) => (
                <>
                  <Header />
                  <Tutorials {...props}></Tutorials>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/overview/:type/:slug"
              render={(props) => (
                <>
                  <Header />
                  <CourseOverview {...props}></CourseOverview>
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/consultations"
              render={(props) => <Consultations {...props}></Consultations>}
            />
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/schedule/:id"
              exact
              render={(props) => <Schedule {...props}></Schedule>}
            ></ProtectedRoute>
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/checkout-consultation"
              exact
              render={(props) => (
                <CheckOutConsulting {...props}></CheckOutConsulting>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/course-content/:slug"
              exact
              render={(props) => (
                <>
                  <Header />
                  <CourseContent1 {...props}></CourseContent1>
                  <Footer />
                </>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/follow-courses/course-content/:slug"
              exact
              render={(props) => (
                <>
                  <Header />
                  <FollowCourseContent {...props}></FollowCourseContent>
                  <Footer />
                </>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/settings"
              exact
              render={(props) => (
                <>
                  <Header />
                  <Settings {...props}></Settings>
                  <Footer />
                </>
              )}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/cart"
              exact
              render={(props) => (
                <>
                  <Header />
                  <Cart {...props}></Cart>
                  <Footer />
                </>
              )}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/check-out"
              exact
              render={(props) => (
                <>
                  <Header />
                  <CheckOut {...props}></CheckOut>
                </>
              )}
            ></ProtectedRoute>
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/my-courses"
              exact
              render={(props) => <MyCourses {...props}></MyCourses>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/my-orders"
              exact
              render={(props) => <MyOrders {...props}></MyOrders>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/my-consultations"
              exact
              render={(props) => <MyConsultations {...props}></MyConsultations>}
            ></ProtectedRoute>
            <Route
              path="/privacy-policy"
              exact
              render={(props) => (
                <PrivacyPolicyPage {...props}></PrivacyPolicyPage>
              )}
            ></Route>
            <Redirect to="/"></Redirect>
          </Switch>
        </LastLocationProvider>
      </Router>
    </div>
  );
}

export default App;
