import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./tailwind.css";
import App from "./routes/App";
import reportWebVitals from "./reportWebVitals";
import Modal from "react-modal";
import StoreProvider from "./global/StoreProvider";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { initInterceptors } from "./services/refresh";

initInterceptors();

Modal.setAppElement("#root");

ReactDOM.render(
  <StoreProvider>
    <App />
    <ToastContainer bodyClassName={() => "text-right text-gray-500 p-4"} />
  </StoreProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// IGQVJYVUdXSy1yWURxR1I5RkJCSFNoamI1dlhWZAmtWSmpDZA2NDN2QtbHl0bHUzWk56WU94NVdyb3dRaDlwSDYyc2JGTGNaVVNuQ2kxckxMN3dXWHg1Qm1JV2tDSHk3ZAFhULVVqa19nUnhWdC1WQTI1dQZDZD
// id 2934397376
