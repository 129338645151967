import React from "react";
import "./HomePage.css";
import HomeFirstSection from "../../Sections/HomeFirstSection/HomeFirstSection";
import WhoAmI from "../../Sections/WhoAmI/WhoAmI";
import Services from "../../Sections/Services/Services";
import Description from "../../Sections/Description/Description";
import Testimonial from "../../Sections/Testimonial/Testimonial";
import Subscribe from "../../Sections/Subscribe/Subscribe";
import FollowMe from "../../Sections/FollowMe/FollowMe";
import { useEffect } from "react";
import SubscribeForm from "../../components/SubscribeForm/SubscribeForm";
import { getTheUserFromStorage } from "../../services/auth";
function HomePage() {
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      var menu = document.getElementById("subscribeModal");
      if (menu.classList.contains("visible")) {
        menu.classList.toggle("visible");
        // menu.classList.toggle("menu__bar")
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="home__page">
      <div class="subscribe__modal " id="subscribeModal">
        <div className="modal__content">
          <div className="modal__right">
            <img src="/images/subscribe.png" alt="subscribe box"></img>
          </div>
          <SubscribeForm />
        </div>
      </div>
      <HomeFirstSection />
      <WhoAmI />
      <Services />
      <Description />
      <Testimonial />
      <Subscribe />
      <FollowMe />
    </div>
  );
}

export default HomePage;
